import * as React from "react";

function SvgRightArrow(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      id=""
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M357.75 169.64L459 270.8a.31.31 0 00.16.16 16.24 16.24 0 014.68 11.42v30.51a16.36 16.36 0 01-4.68 11.43.31.31 0 00-.16.16L357.75 425.64v-56.26a17.16 17.16 0 014.73-11.82 4.71 4.71 0 01.67-.67L419 301l3.41-3.41-3.41-3.34-55.42-55.46-1.43-1.43a17.28 17.28 0 01-4.37-11.47z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgRightArrow;
