import * as React from "react";

function SvgBloccaUtente(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Blocca_utente_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Blocca_utente_svg__cls-1"
        d="M474.59 216c0 3-.1 6-.37 8.9-1.37 17.27-6.78 32.9-14.83 45.25-7.95 12.13-18.49 21.09-30.47 25.22a.4.4 0 01-.32.1q-31 8.91-62.94 15.26a54.67 54.67 0 01-9.66-61.14c4.61-9.43 16.16-26.17 24.16-34 1.75-1.7 3.29-3.66 5.14-5.41a195.61 195.61 0 0118-15.63C417.53 184 439.09 173 452 169.64a182.15 182.15 0 01-4 28.77A182.89 182.89 0 00470.36 186a105.26 105.26 0 014.23 30zM447.12 378.94a.87.87 0 00.15.13 6.66 6.66 0 004.53 1.8h42.74a17.58 17.58 0 0111 3.86l.48.4 22.6 22.6-.12-2.68-1.2-24.75v-.68a17.15 17.15 0 00-12.34-15.8v-10.91a21.93 21.93 0 00-.28-3.46 23.67 23.67 0 00-23.35-20.25h-17.9a23.71 23.71 0 00-23.68 23.71v10.91a16.6 16.6 0 00-5.48 2.78 16.94 16.94 0 00-4.48 5zm22.48-35.52a9.91 9.91 0 013.86-.75h17.82a10.06 10.06 0 013.86.75 10.22 10.22 0 016.35 8.64 8 8 0 010 .85v10.24h-38.3v-10.24a10.18 10.18 0 012.71-6.91 9.94 9.94 0 013.7-2.58zM529.86 434.07h-95l.6-12.47 1.41-30.22.13-2.38 23.56 23.55.43.35a16.68 16.68 0 0010.79 4h41.45a10.4 10.4 0 015.88 1.8 10.2 10.2 0 011.58 1.3l8.91 8.91z"
      />
      <path
        className="Blocca_utente_svg__cls-1"
        d="M514.76 315.18c0 2.93-.1 5.86-.32 8.74a36.81 36.81 0 00-23.13-8.21h-17.88a37.15 37.15 0 00-37.15 37.15v2.85c-9.8 5.72-11.55 13.55-12.14 19.3L422 421l-.23 4.78h-130.1c2.71-11.19 5.66-22.25 8.89-33.34 1.8-6.31 3.66-12.62 5.68-18.85.05-.13.05-.28.1-.38.38-1.18 1.23-3.75 1.23-3.75a53.7 53.7 0 0135.77-33.4c1-.32 2-.52 3.06-.75s2.07-.35 3.13-.57q21.29-3.8 42.18-8.79 26-6 51.24-13.84c23.35-7 46.26-15.2 68.66-24.26a119.72 119.72 0 013.15 27.33zM509.5 405.15h-37.42a5.64 5.64 0 01-3.08-.9 4.37 4.37 0 01-.6-.42l-.77-.8-.58-.55-6.26-6.26-1.07-1.1-.75-.76-1.83-1.8h37.42a6 6 0 013.71 1.3 1.18 1.18 0 00.17.2l.4.4.5.46 7.61 7.66.76.75z"
      />
    </svg>
  );
}

export default SvgBloccaUtente;
