import * as React from "react";

function SvgGift(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Gift_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Gift_svg__cls-1"
        d="M524.8 304.45v59.67l-103.86 54.83-101.13-53.42v-59.67l52.72 27.83 13.59 7.22 34.82-34.83 22.87 22.87 11.96 11.96 13.59-7.22 55.44-29.24z"
      />
      <path
        className="Gift_svg__cls-1"
        d="M421 280.89l-10.38 10.42-22.82 22.77-2.33 2.33-3.26 3.26-83.28-44a5.9 5.9 0 01-.83-.44 9.67 9.67 0 01-5.11-8.53 9.54 9.54 0 012.2-6.16 1.3 1.3 0 01.31-.35l.66-.66a1.3 1.3 0 01.35-.31l28.8-28.84 95.64 50.43zM548.94 266.73a9.67 9.67 0 01-5.1 8.53 5.9 5.9 0 01-.83.44l-83.28 44-3.21-3.21-2.33-2.33L421 280.89v-.05l95.64-50.43 28.8 28.84a1.3 1.3 0 01.35.31l.66.66a1.3 1.3 0 01.31.35 9.54 9.54 0 012.18 6.16zM489.23 203l-13.67 13.85a14.67 14.67 0 01-9.28 4.44 10.52 10.52 0 01-10.47-5.4l23-23.31zM386 215.9a10.5 10.5 0 01-10.46 5.4 14.9 14.9 0 01-9.33-4.44l-13.63-13.8L363 192.64zM430.84 176.32l-2.09 47.6a7.67 7.67 0 01-7.83 7.15 7.68 7.68 0 01-7.83-7.15l-2.09-47.6z"
      />
    </svg>
  );
}

export default SvgGift;
