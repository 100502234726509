import * as React from "react";

function SvgUpArrow(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      id=""
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M282.78 350.66l101.16-101.2a.31.31 0 00.16-.16 16.2 16.2 0 0111.42-4.68H426a16.27 16.27 0 0111.42 4.68.31.31 0 00.16.16l101.17 101.2h-56.23a17.17 17.17 0 01-11.82-4.72 5.45 5.45 0 01-.68-.68l-55.85-55.81-3.41-3.45-3.38 3.42-55.46 55.42-1.43 1.42a17.25 17.25 0 01-11.49 4.4z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgUpArrow;
