import * as React from "react";

function SvgRifiuta(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      id=""
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M548.94 378.43a52.08 52.08 0 01-81.36 9.75L420.37 341l-45.27 45.24c-.57.57-1.14 1.2-1.76 1.77a52 52 0 01-80.4-10.72l56.17-56.1 15.39-15.4 10.38-10.32-7.07-7.07h-.06l-71.5-71.5v-.05a52.08 52.08 0 0181.36-9.75l42.82 42.82 42.76-42.82A51.64 51.64 0 01500 191.82a52.21 52.21 0 0144.53 25v.05L473 288.4h-.06l-7 7z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgRifiuta;
