import * as React from "react";

function SvgStack1(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Stack_1_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Stack_1_svg__cls-1"
        d="M435.6 205.49v136.8l-24.9 32.94h-.06l-24.89-32.94V221.14l27.86-27.86v-23.64a39.62 39.62 0 0110.18 7.36 40.22 40.22 0 0111.81 28.49z"
      />
      <path
        className="Stack_1_svg__cls-1"
        d="M502.81 260h-53.95v26.79H490a2.3 2.3 0 011.85 3.68l-79.22 105.12a2.32 2.32 0 01-3.68 0L329.7 290.43a2.3 2.3 0 011.85-3.68h40.91V260h-53.71a21.77 21.77 0 00-17.41 34.87L393.39 417a21.79 21.79 0 0034.79 0l92-122.14c10.82-14.37.59-34.86-17.37-34.86z"
      />
    </svg>
  );
}

export default SvgStack1;
