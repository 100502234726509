import * as React from "react";

function SvgDesktop(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Desktop_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Desktop_svg__cls-1"
        d="M492.22 295.57a24 24 0 00-24 24v83.23h46.66a24 24 0 0024-24v-83.23zm18.1 10.14v31.13a5.14 5.14 0 01-.08.88 5.34 5.34 0 01-5 4.49h-6.78v-31.12a5.38 5.38 0 015.39-5.38z"
      />
      <path
        className="Desktop_svg__cls-1"
        d="M503.35 217.06v65.71h-11.12a36.78 36.78 0 00-36.81 36.79v15h-137V228a10.94 10.94 0 0110.92-10.93z"
      />
      <path
        className="Desktop_svg__cls-1"
        d="M302.54 315l-19.65 19V212.51a31.9 31.9 0 0131.91-31.9H441l-21.46 20.83H322a19.48 19.48 0 00-19.49 19.5zM420 371.38v22.25h35.07v20.84h-122v-11.9a8.92 8.92 0 018.93-8.94h43.19v-22.25h-102.3v-11.15l19.64-19.06v9.37h144a8.93 8.93 0 018.93 8.94v11.9zM538.89 180.61v102.16h-11.62a8 8 0 01-8-8v-73.33h-72.75L468 180.61z"
      />
    </svg>
  );
}

export default SvgDesktop;
