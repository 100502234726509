import axios from "axios";
import {getApiUrl} from "./apiHelpers";

export function confirmOrganizationEntryRequest(organizationId, notificationId, notificationToken) {
    return axios.post(getApiUrl('organizations/' + organizationId + '/accept-entry-request'), {
        notification_id: notificationId,
        token: notificationToken,
    })
}

export function refuseOrganizationEntryRequest(organizationId, notificationId, notificationToken) {
    return axios.post(getApiUrl('organizations/' + organizationId + '/refuse-entry-request'), {
        notification_id: notificationId,
        token: notificationToken,
    })
}