import * as React from "react";

function SvgRegolamento(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      id=""
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M494.07 169.77l.09 44.12h44.47v-.14zm-17.7 61.72l-.13-61.72H282.63V418.2a3.39 3.39 0 005.75 2.35l16.53-16.36a3.42 3.42 0 014.77 0l20.88 20.61a3.41 3.41 0 004.76 0l21.16-20.61a3.41 3.41 0 014.76 0l20.89 20.61a3.41 3.41 0 004.76 0l21.16-20.61a3.41 3.41 0 014.76 0l20.89 20.61a3.41 3.41 0 004.76 0l21.2-20.61a3.42 3.42 0 014.77 0l20.88 20.61a3.41 3.41 0 004.76 0l21.16-20.61a3.41 3.41 0 014.76 0l16.89 16.67a3.37 3.37 0 005.75-2.35v-187zm21.88 117.45H334.88A11.78 11.78 0 01323 337.19v-8.47h163.35a11.82 11.82 0 0111.9 11.75zm0-41.06H334.88A11.78 11.78 0 01323 296.13v-8.46h163.35a11.81 11.81 0 0111.9 11.74zm0-41.05H334.88A11.79 11.79 0 01323 255.08v-8.47h163.35a11.81 11.81 0 0111.9 11.75z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgRegolamento;
