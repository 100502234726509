/* NOTE: Typescript definitions... We can't use them in the current setup.

type UserInfo = {
    id: number,
    api_token: string,
    api_token_expiry: Date | string,
}

type LoggedInMsg = {
    loggedIn: UserInfo
}

type LoggedOutMsg = {
    loggedOut: UserInfo
}

type MMAction =
    | 'started'
    | 'stopped'

type MatchmakingMsg = {
    matchmaking: MMAction
}

type Message =
    | MatchmakingMsg
    | LoggedInMsg
    | LoggedOutMsg

*/

/**
 *
 * @param {object} msg
 */
function sendNotification(msg) {
    // @ts-ignore
    if (typeof window?.ReactNativeWebView?.postMessage === 'function') {
        // ReactNativeWebView needs a string
        const msgStr = JSON.stringify(msg);
        // @ts-ignore
        window.ReactNativeWebView.postMessage(msgStr);
    }
}

/**
 * Notifies the a matchmaking action to the container app,
 * which should receive a JSON string like:
 *      {
 *          "matchmaking": "started"
 *      }
 *
 * @param {"started"|"stopped"|"ready"|"notReady"|"waitingForReady"} action
 */
export function notifyMMAction(action) {
    sendNotification({matchmaking: action})
}

/**
 * Notifies the new user data to the container app,
 * which should receive a JSON string like:
 *      {
 *          "loggedId": {
 *              "id": 42,
 *              "first_login": 0,
 *              "api_token": "...",
 *              "api_token_expiry": "2021-10-30T15:57:36+00:00"
 *          }
 *      }
 *
 * @param {{id: number,api_token: string,api_token_expiry: Date | string}} loggedIn user info
 */
export function notifyLoggedIn(loggedIn) {
    sendNotification({loggedIn})
}

/**
 * Notifies that the user has logged out,
 * the container app should receive an JSON string like:
 *      {
 *          "loggedOut": {
 *              "id": 42,
 *              "first_login": 0,
 *              "api_token": "...",
 *              "api_token_expiry": "2021-10-30T15:57:36+00:00"
 *          }
 *      }
 *
 * @param {{id: number,api_token: string,api_token_expiry: Date | string}} loggedOut user info
 */
export function notifyLoggedOut(loggedOut) {
    sendNotification({loggedOut})
}