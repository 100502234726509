import * as React from "react";

function SvgLeftArrow(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      id=""
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M463.8 425.64L362.59 324.48a.29.29 0 00-.15-.16 16.25 16.25 0 01-4.69-11.43v-30.51a16.28 16.28 0 014.69-11.38.29.29 0 00.15-.16l101.21-101.2v56.25a17.17 17.17 0 01-4.72 11.82 5.45 5.45 0 01-.68.68l-55.82 55.86-3.41 3.41 3.41 3.37L458 356.49l1.43 1.43a17.25 17.25 0 014.37 11.46z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLeftArrow;
