import {getApiUrl} from "./apiHelpers";
import axios from "axios";

export function acceptPartyInvitation(partyId, notificationId, notificationToken) {
    return axios.post(getApiUrl('parties/' + partyId + '/accept-invitation'), {
        notification_id: notificationId,
        token: notificationToken,
    });
}

export function refusePartyInvitation (partyId, notificationId, notificationToken) {
    return axios.post(getApiUrl('parties/' + partyId + '/refuse-invitation'), {
        notification_id: notificationId,
        token: notificationToken,
    });
}