import * as React from "react";

function SvgInvitaNelTeam(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>
          {
            ".Invita_nel_team_svg__cls-1{fill:#efac18;opacity:.59}.Invita_nel_team_svg__cls-2{fill:#ffb400}"
          }
        </style>
      </defs>
      <path
        className="Invita_nel_team_svg__cls-1"
        d="M365.36 241.05c0 1.36 0 2.7-.16 4a44.09 44.09 0 01-6.7 20.43c-3.59 5.48-8.35 9.52-13.76 11.38a.16.16 0 01-.14.05q-14 4-28.42 6.89a24.6 24.6 0 01-5.53-24.66 25 25 0 011.17-2.92 71.13 71.13 0 0110.91-15.34c.79-.76 1.49-1.65 2.32-2.44a87.85 87.85 0 018.11-7.05c6.44-4.79 16.17-9.74 22-11.27a82.82 82.82 0 01-1.79 13 83.7 83.7 0 0010.07-5.6 47.6 47.6 0 011.92 13.53zM370.31 309.59c-.29.07-.54.1-.83.16l-.51.1-1.09.19-.51.1a26.17 26.17 0 00-2.78.67 43 43 0 00-27.87 25h-53.94c1.21-5 2.55-10 4-15 .8-2.85 1.66-5.72 2.56-8.54 0 0 0-.12.06-.16.16-.54.29-1 .48-1.53a.22.22 0 01.07-.16 24.22 24.22 0 0116.14-15.08 13.74 13.74 0 011.37-.32l1.44-.29c6.39-1.12 12.75-2.46 19-4q11.74-2.74 23.14-6.24c3.45-1.05 6.91-2.14 10.32-3.32v2a43.24 43.24 0 008.95 26.42z"
      />
      <path
        className="Invita_nel_team_svg__cls-2"
        d="M493.36 346.79a12.16 12.16 0 01-8.54 3.53h-12.2v23.87a12 12 0 01-11.37-3.19 11 11 0 01-1.32-1.6c-.3-.44-.59-.92-.86-1.4a11.75 11.75 0 01-1.36-5.53v-12.15h-23.89a12.05 12.05 0 0111.74-14.94h12.15V311.5a12.06 12.06 0 0111.41 3.2 10.61 10.61 0 011.42 1.69v.06a12.13 12.13 0 012 6.8v12.13h23.95c0 .08 0 .18.05.26a12 12 0 01-3.18 11.15zM446.75 248.61c0 1.83-.06 3.62-.22 5.37a58.58 58.58 0 01-8.95 27.29c-4.79 7.32-11.15 12.72-18.37 15.22a.26.26 0 01-.2.06q-18.69 5.37-38 9.2a32.71 32.71 0 01-9-22.62 32.34 32.34 0 011.66-10.32 31.08 31.08 0 011.56-3.9c2.78-5.69 9.75-15.79 14.57-20.49 1.06-1 2-2.2 3.1-3.26a117.79 117.79 0 0110.84-9.42c8.59-6.4 21.6-13 29.4-15.06a109.68 109.68 0 01-2.4 17.36 111.61 111.61 0 0013.46-7.48 63.39 63.39 0 012.55 18.05z"
      />
      <path
        className="Invita_nel_team_svg__cls-2"
        d="M433.82 357.79h16.4v1.62a71.57 71.57 0 01-13 10.57 21.94 21.94 0 01-2.49 1.54 30 30 0 01-14.38 3.65h-83.91q2.44-10.11 5.37-20.11c1.08-3.8 2.21-7.61 3.42-11.37a1.19 1.19 0 010-.22c.24-.71.74-2.28.74-2.28a32.44 32.44 0 0121.58-20.13 15.6 15.6 0 011.85-.44c.64-.1 1.25-.24 1.89-.36q12.84-2.29 25.43-5.3 15.68-3.65 30.91-8.35c14.09-4.24 27.89-9.17 41.41-14.63a71.64 71.64 0 011.85 14.66 19.41 19.41 0 00-10.35-3 20.1 20.1 0 00-4.64.56 7.51 7.51 0 00-5.73 7.28v16.39h-4.65a19.55 19.55 0 00-19 24.19 7.49 7.49 0 007.3 5.73z"
      />
      <path
        className="Invita_nel_team_svg__cls-1"
        d="M538.78 285.76a54.52 54.52 0 01-25.24 46 13.83 13.83 0 01-1.85 1.15 22.25 22.25 0 01-7.5 2.48c-.09-.58-.2-1.14-.34-1.72a7.48 7.48 0 00-7.28-5.8h-13.2a3.26 3.26 0 01-3.26-3.26 84.31 84.31 0 00-.13-33.24c1.08-.26 2.21-.51 3.29-.78 7.82-1.8 15.52-3.89 23.12-6.22 10.54-3.18 20.88-6.87 31-10.95a54 54 0 011.39 12.34zM520.66 241c0 1.36 0 2.7-.17 4a43.89 43.89 0 01-6.69 20.41c-3.59 5.48-8.34 9.52-13.74 11.38a.18.18 0 01-.15.05q-14 4-28.4 6.88A24.58 24.58 0 01466 259.1a25 25 0 011.17-2.92 70.63 70.63 0 0110.9-15.32c.79-.77 1.49-1.65 2.32-2.44a86.56 86.56 0 018.11-7c6.43-4.78 16.15-9.73 22-11.26a81.56 81.56 0 01-1.8 13 83.63 83.63 0 0010.07-5.59 47.46 47.46 0 011.89 13.43z"
      />
    </svg>
  );
}

export default SvgInvitaNelTeam;
