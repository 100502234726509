import React from 'react';
import {Utente} from "./icons2";

const UserAvatar = ({user, sizeClass, bordered, className, defaultAvatarClassName}) => {
    return (
        <div className={"UserAvatar relative rounded-full"
            + (bordered ? ' border border-primary' : '')
            + (className ? ' '+className : '')
        }
        >
            {user && (user.avatar_url) ?
                <img src={user.avatar_url}
                     alt=""
                     className={`UserAvatarImg ${sizeClass} rounded-full`}
                />
                :
                <Utente className={`UserAvatarDefault ${sizeClass ?? ''} rounded-full p-1 ${defaultAvatarClassName || ''}`} />
                // <IconAvatar className={`fill-current ${sizeClass} rounded-full p-1 ${defaultAvatarClassName || ''}`}/>
            }
        </div>
    );
};

UserAvatar.defaultProps = {
    sizeClass: "w-12 h-12",
}

export default UserAvatar;