import * as React from "react";

function SvgDownArrow(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      id=""
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M538.78 244.62l-101.17 101.2a.31.31 0 00-.16.16 16.24 16.24 0 01-11.45 4.68h-30.48A16.31 16.31 0 01384.1 346a.31.31 0 00-.16-.16L282.78 244.62H339a17.13 17.13 0 0111.82 4.72 5.41 5.41 0 01.68.67l55.85 55.82 3.42 3.41 3.37-3.41 55.46-55.42 1.43-1.43a17.24 17.24 0 0111.46-4.36z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDownArrow;
