import * as React from "react";

function SvgWallet(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Wallet_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Wallet_svg__cls-1"
        d="M459.67 316.34v37.81h65.06a11.44 11.44 0 001.19 0L526 370a32.37 32.37 0 01-32.37 32.58H291.8a9 9 0 01-9-9V277.65a35.51 35.51 0 0135.51-35.51h198.91a9 9 0 019 9v39.4h-38.53c-15.43.02-28.02 11.6-28.02 25.8zM467.63 229H338.88l113.64-36a4.41 4.41 0 015.55 3zM503.74 229H481l-7.22-23 17.69-3.17a4.39 4.39 0 015 3z"
      />
      <path
        className="Wallet_svg__cls-1"
        d="M538.78 317.18v11.19c0 6.06-5.43 11.16-12.62 11.79a8.79 8.79 0 01-1.36.07h-51.16v-23.88c0-6.52 6.33-11.85 14-11.85h38.48a12.7 12.7 0 0112.66 12.68z"
      />
    </svg>
  );
}

export default SvgWallet;
