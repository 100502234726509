import * as React from "react";

function SvgNotificaProtest(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Notifica_protest_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Notifica_protest_svg__cls-1"
        d="M548.84 252.12a52.34 52.34 0 01-51.48-43.2H292.92V370a31.48 31.48 0 0031.47 31.47h224.53V252.12zM498.37 352H358.93a15.46 15.46 0 01-15.46-15.49V325.3h139.41a15.49 15.49 0 0115.49 15.49zm0-61.27H358.93a15.46 15.46 0 01-15.46-15.49V264h139.41a15.49 15.49 0 0115.49 15.5z"
      />
      <circle
        className="Notifica_protest_svg__cls-1"
        cx={549.06}
        cy={199.67}
        r={31.53}
      />
    </svg>
  );
}

export default SvgNotificaProtest;
