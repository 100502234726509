import React from "react";

export default function useFaqs() {
    return [
        {
            title: "Cos’è YAK?",
            details: <span>
                Yak Games è la community che tiene sempre attiva la competizione esport. La nostra filosofia è
                #EveryGameMatters: con Yak Games ogni partita ha valore, perché vengono premiati
                settimanalmente sia i più FORTI che i più TENACI.
            </span>,
        },
        {
            title: "Come funziona la Yak Mode?",
            details: <span>
                La Yak Mode è il nostro innovativo sistema di PREMIAZIONE che ti accompagnerà in ogni tuo match!<br/>
                Per ogni vittoria rubi il bottino al tuo avversario che viene aggiunto al tuo forziere. Dopo 3 vittorie
                consecutive otterrai la chiave per aprire il tuo forziere e sbloccare le ricompense.
            </span>,
        },
        {
            title: "A cosa servono i COINS?",
            details: <span>
                I COINS sono una delle due VALUTE presenti su Yak Games.<br/>
                Ti danno accesso alle arene START e possono essere convertiti nell’altra valuta: lo YAK.
            </span>,
        },
        {
            title: "A cosa servono gli YAK?",
            details: <span>
                Gli YAK sono una delle due VALUTE presenti su Yak Games che ti danno accesso alle arene PRO.<br/>
                Ottieni YAK Riscattabili ogni volta che vinci per continuare la tua scalata, possono essere usati per
                riscattare le Gift Card nello shop di Yak Games.
            </span>
        },
        {
            title: "Che differenza c'è tra YAK e YAK Riscattabili?",
            details: <span>
                Lo YAK è la moneta che acquisti e che ti permette di iscriverti alle arene PRO.<br/>
                Gli YAK Riscattabili sono YAK che vinci e che puoi usare per iscriverti di nuovo alle arene PRO
                oppure per riscattare Buoni Amazon sullo Yak Shop.<br/>
                Questa divisione serve a tutelare te e a rispettare le leggi italiane ed europee sulla lotta al riciclaggio
                di denaro e alla criminalità.
            </span>
        },
        {
            title: "Come mai il cambio tra il valore dello YAK e il valore del COINS varia nel tempo?",
            details: <span>
                La variazione del cambio ci permette di proteggere la community Yak e la piattaforma da frodi e usi
                illegittimi.
            </span>
        },
        {
            title: "Cosa cambia tra arene Start e Pro?",
            details: <span>
                Le arene Start permettono di guadagnare COINS che servono per essere convertiti in YAK.<br/>
                Le arene Pro fanno guadagnare YAK Riscattabili che possono essere subito utilizzati per ottenere Gift Card.
            </span>
        },
        {
            title: "Come funzionano le classifiche Forza e Tenacia?",
            details: <span>
                Su Yak Games puoi trovare 2 tipi di CLASSIFICHE: quella della FORZA che si basa sul rapporto
                vittorie/sconfitte e che punta a premiare i giocatori più forti, e quella della TENACIA che si basa sui
                punti accumulati giocando e punta a valorizzare gli utenti che si impegnano di più. Chi vince nella
                classifica Forza non può vincere in quella della Tenacia (e viceversa).
            </span>
        },
        {
            title: "Ogni quando si ricevono i premi della classifica Forza e Tenacia?",
            details: <span>
                Ogni domenica a mezzanotte finisce la Season settimanale. I premi verranno ricevuti la mattina
                seguente e notificati tramite mail. Se sei uno dei vincitori riceverai una notifica via mail.
            </span>
        },
        {
            title: "Se finisco i COINS posso comunque giocare?",
            details: <span>
                Se finisci i COINS, ogni giorno a mezzanotte vengono ricaricati nuovi COINS per poter continuare a
                giocare su Yak Games.
            </span>
        },
        {
            title: "Ho la PlayStation 5 e vorrei giocare a FIFA 22, come faccio?",
            details: <span>
                Per giocare a FIFA 22 con gli altri utenti è necessario scaricare la versione retrocompatibile di FIFA 22
                per PlayStation 4, non preoccuparti è gratuita!
            </span>
        },
        {
            title: "Cosa vuol dire che sono stato disabilitato?",
            details: <span>
                Se inserendo i dati di accesso è apparsa questa voce, vuol dire che il tuo account è stato disabilitato
                a seguito di una possibile violazione dei nostri termini e condizioni.<br/>
                Se pensi che possa esserci stato un errore invia una mail a support@yak.games.
            </span>
        },
        // {
        //     title: "Se dopo aver trovato la partita il mio avversario non dovesse presentarsi, come mi devo comportare?",
        //     details: <span>
        //         Il nostro consiglio è aspettare la fine della partita (max 15 min) senza aprire un protest, così facendo
        //         potrai ottenere una vittoria
        //         {/*FIXME*/}
        //     </span>
        // },
        {
            title: "Perché non riesco a giocare e il sistema mi dice di completare il profilo?",
            details: <span>
                Significa che devi completare in ogni sua parte il tuo profilo ed essere tesserato per poter continuare
                l’esperienza in Yak Games.
            </span>
        },
        {
            title: "Non riesco più a giocare perché sono stato coinvolto in un protest, come posso fare per continuare a giocare?",
            details: <span>
                Il nostro consiglio è di attendere che il protest venga risolto. Cerchiamo sempre di rispondere a tutte
                le vostre richieste per permettervi di giocare il più possibile, e ci scusiamo in anticipo se i tempi
                dovessero prolungarsi. Non ti preoccupare, la pazienza viene sempre ripagata!
            </span>
        },
        {
            title: "Ho fatto una partita su League of Legends ma nel mio storico partite (match history) non appare, come posso fare?",
            details: <span>
                Quando crei la partita è fondamentale selezionare la modalità di gioco Torneo (Tournament Draft),
                perché rimane nel tuo storico partite e potrai, quindi, fare lo screenshot. Le partite prive di screenshot
                non saranno convalidate.
            </span>
        },
        {
            title: "Perché devo tesserarmi per giocare su Yak Games?",
            details: <span>
                I tornei e le competizioni che si svolgono su Yak Games sono promossi dall’Associazione Sportiva
                Dilettantistica The Universe e sono quindi riservati ai suoi tesserati. La quota di iscrizione ti sarà
                offerta direttamente da Yak Games e non dovrai sostenere nessun costo.
            </span>
        },
        {
            title: "Che età devo avere per potermi tesserare?",
            details: <span>
                Puoi iscriverti in autonomia all’Associazione The Universe se hai compiuto almeno 16 anni. Se hai
                meno di 16 anni, per tesserarti è necessaria l’autorizzazione espressa di chi ha la responsabilità
                genitoriale. Ti invitiamo a seguire sempre le istruzioni fornite da The Universe per completare il
                tesseramento e a non dimenticarti di comunicare le informazioni richieste.
            </span>
        },
        {
            title: "Quali dati e documenti servono per il tesseramento?",
            details: <span>
                Per iscriverti a The Universe ti vengono richiesti alcuni dati personali e di firmare alcuni moduli: si
                tratta delle informazioni e dei documenti richiesti dalla legge per poterti tesserare a un’Associazione
                Sportiva Dilettantistica e poter partecipare alle iniziative, nonché per poter vincere i premi messi in
                palio nelle relative competizioni. Presta sempre la massima attenzione nell’inserire queste
                informazioni e nel completare la procedura inviando tutti i documenti richiesti: se fornisci dati falsi o
                errati o se non invii i documenti necessari a The Universe, il tesseramento non risulterà valido e il tuo
                account sarà bannato.
            </span>
        },
        {
            title: "Come posso aggiungere i Buoni Amazon riscattati nel mio saldo Amazon?",
            details: <span>
                Vai sulla Home di Amazon ed entra col tuo profilo. Clicca su “Account e Liste” in alto a destra (sotto il
                tuo nome), clicca su “Buoni Regalo e Ricarica” e clicca infine su “Aggiungi al mio Account”. In questa
                pagina (https://www.amazon.it/gc/redeem) aggiungi il codice di 14-15 caratteri alfanumerici e
                concludi la procedura cliccando su “Aggiungi al mio account”.
            </span>
        },
        {
            title: "Ho problemi e vorrei contattare l’assistenza, come faccio?",
            details: <span>
                Puoi scriverci una mail support@yak.games.
            </span>
        }
    ];
}
