import * as React from "react";

function SvgAggiungi(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      id=""
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M534.69 313.49a48.72 48.72 0 01-34.48 14.31H451.1v96.45a48.75 48.75 0 01-60.31-47.34V327.8h-96.46a48.71 48.71 0 0147.35-60.32h49.11V171a48.7 48.7 0 0160.31 47.29v49.16h96.46a48.66 48.66 0 01-12.87 46.04z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgAggiungi;
