import React from 'react';
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
    barColors: {
        "0": "#ffa900",
        "1.0": "#ffa900"
    },
    shadowBlur: 5
});

const Loading = (props) => (
    <TopBarProgress/>
);

export default Loading;