import './UserAvatarSlot.css'
import * as Icons from "./icons2";
import React, {useState} from "react";
import UserAvatar from "./UserAvatar";
import Spinner from "./Spinner";
import {useIntl} from "react-intl";
import useOnclickOutside from "react-cool-onclickoutside";

export function UserAvatarSlotsContainer({children, opponents, party, lay,...rest}) {
    return <div className={"UserAvatarSlotsContainer"+(opponents ? ' opponents' : '')+(party ? ' party' : '')+(lay ? ' lay' : '')} {...rest}>
        {children}
    </div>
}

export default function UserAvatarSlot({user, earmark, invited, onCancelInvite, stack, usernameAlwaysOn, overlay, drop, onClick, ...rest}) {
    const intl = useIntl();
    const [dropOpen, setDropOpen] = useState(false);
    const setClickOutRef = useOnclickOutside(() => setDropOpen(false));

    return <div className={
        "UserAvatarSlot"
        + (user ? ' filled' : ' cursor-pointer')
        + (invited ? ' invited' : '')
        + (usernameAlwaysOn ? ' usernameAlwaysOn' : '')
        + (dropOpen ? ' dropOpen' : '')
        + (drop || onClick ? ' cursor-pointer' : '')
    } ref={setClickOutRef} onClick={onClick ? onClick : (drop ? () => setDropOpen(o => !o) : undefined)} {...rest}>
        <div className="icon">
            {user
                ? <UserAvatar user={user} sizeClass="avatarSize"/>
                : <Icons.AggiungiAmico className="unshift"/>}
        </div>
        {overlay && <div className="overlay">{overlay}</div>}
        {stack && <div className="number">{stack.winsCount}</div>}
        {earmark && <div className="earmark">{earmark}</div>}
        {invited && <>
            <div className="earmark remove" onClick={onCancelInvite}>
                &times;
            </div>
            <div className="trail">
                {/* NOTE: I'm reusing .trail just because it doesn't look disgusting. */}
                <Spinner/>
            </div>
        </>}
        <div className="trail">
            {stack && <div title={stack && stack.coins
                ? intl.formatMessage({description: "Player's YAK", defaultMessage: "YAK del giocatore"})
                : intl.formatMessage({description: "Player's COIN", defaultMessage: "COIN del giocatore"})
            }>
                {stack ? (stack.coins || (stack.yaks || 0)) : '?'}
            </div>}
        </div>
        {user?.username && <div className="username">
            <div>{user.username}</div>
        </div>}
        {drop && <div className="drop cursor-default">
            <div>
                {user.username}<br />
                {drop}
            </div>
        </div>}
    </div>
}