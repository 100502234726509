import React, {lazy, Suspense, useEffect, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import './index.css';
import routes from './routes';
import {FormattedMessage, IntlProvider} from "react-intl";
import Loading from "./components/Loading";
import BlockFullScreenAlert from "./components/BlockFullScreenAlert";
import {isIE, isMobileOnly, isTablet, withOrientationChange} from "react-device-detect";
import axios from "axios";
import {deleteApiToken, getApiToken, isApiTokenValid} from "./utility/apiToken";
import {updateMatchmakingStatus} from './contexts/MMContext';
import Modal from "react-modal";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getServerErrorMessage} from "./utility/form";
import useMatchmaking from "./hooks/useMatchmaking";
import WsManager from "./components/WSManager";
import {updatePartyStatus} from "./contexts/PartyContext";
import PreMatchModal from "./components/modals/PreMatchModal";
import useParty from "./hooks/useParty";
import {updateUserInfo} from "./contexts/UserContext";
import useUser from "./hooks/useUser";
import {updateNotifications} from "./contexts/NotificationsContext";
import {useNotificationsDispatch} from "./hooks/useNotifications";
import {getUA} from 'react-device-detect';
import {getUADevice} from "./utility/userAgent";
import {Helmet} from "react-helmet";
import {useNoWsAutoRefresher} from "./hooks/useNoWsAutoRefresher";
import IosPullToReloadWrapper from "./components/IosPullToReloadWrapper";

const PartyModal = lazy(() => import("./components/modals/PartyModal"));
const MMUnableToStart = lazy(() => import('./components/MMUnableToStart'));

// noinspection JSUnresolvedFunction
Modal.setAppElement('#root');

//set api token for all api calls
axios.interceptors.request.use(function (config) {
    if (config.url.startsWith(process.env.BACKEND_URL) && config.url.includes('/api/v1/')) {
        config.headers.Authorization = `Bearer ${getApiToken()}`;
    }
    return config;
});

//redirect to login page if response is 401
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    if (error.config.url.includes('/api/v1/')
        && error.response
        && error.response.status === 401
        && !error.config.url.includes('/api/v1/login')
    ) {
        // set counter to prevent problem
        let crtCounter = localStorage.getItem("yakAxiosRedirectCounter");
        if (isNaN(crtCounter)) {
            crtCounter = 0;
        }
        else {
            crtCounter = parseInt(crtCounter)
        }

        localStorage.setItem("yakAxiosRedirectCounter", ++crtCounter);

        if (crtCounter >= 3) {
            deleteApiToken();
            window.location.href = '/500';
        }
        else {
            deleteApiToken();
            window.location.href = '/';
        }
    }
    else {
        localStorage.setItem("yakAxiosRedirectCounter", 0);
    }

    return Promise.reject(error);
});

function App(props) {
    const [isLogged] = useState(isApiTokenValid());

    // eslint-disable-next-line
    const [userState, userDispatch] = useUser();
    // eslint-disable-next-line
    const [mmState, mmDispatch] = useMatchmaking();
    // eslint-disable-next-line
    const [partyState, partyDispatch] = useParty();

    const notificationsDispatch = useNotificationsDispatch();

    const {isMobileApp, isIOSApp} = getUADevice(getUA);

    const noWsAutoRefresher = useNoWsAutoRefresher()

    useEffect(() => {

        if (isLogged) {
            //get signed-in user info
            updateUserInfo(userDispatch)
                .catch(error => toast.error(getServerErrorMessage(error)));
            //get matchmaking status
            updateMatchmakingStatus(mmDispatch)
                .catch(error => toast.error(getServerErrorMessage(error)));
            // get party status
            updatePartyStatus(partyDispatch)
                .catch(error => toast.error(getServerErrorMessage(error)));
            //get notifications
            updateNotifications(notificationsDispatch)
                .catch(error => toast.error(getServerErrorMessage(error)));
        }
    }, [isLogged, mmDispatch, partyDispatch, userDispatch, notificationsDispatch, noWsAutoRefresher]);

    useEffect(() => {
        // set page viewport
        if (isIOSApp) {
            document.getElementById('yak_viewport').setAttribute('content', 'width = 350, user-scalable=no');
        } else if (isTablet){
            document.getElementById('yak_viewport').setAttribute('content','width = 1250, user-scalable=no');
        } else if (isMobileOnly){
            document.getElementById('yak_viewport').setAttribute('content','width = 375, user-scalable=no');
        }
    },[isIOSApp]);

    // noinspection JSUnusedLocalSymbols,ReservedWordAsName,JSUnresolvedFunction,JSRemoveUnnecessaryParentheses
    return (
        <IntlProvider locale={props.locale} messages={props.messages} defaultLocale={props.locale}>
            <IosPullToReloadWrapper>
            <Router>
                <Suspense fallback={<Loading/>}>
                    <Switch>
                        {
                            routes.map((route, i) => {
                                if (
                                    typeof route.authState === 'undefined'
                                    || (route.authState === "private" && isLogged)
                                    || (route.authState === "public" && !isLogged)
                                ) {
                                    return (
                                        <Route {...route} key={i}/>
                                    )
                                }
                                else {
                                    return (
                                        <Route key={i} exact={route.exact} path={route.path} render={() => (
                                            <Redirect to={route.redirectRoute}/>
                                        )}/>
                                    )
                                }
                            })
                        }
                    </Switch>
                </Suspense>

                {/*listen on global websocket events*/}
                <WsManager mmDispatch={mmDispatch} partyDispatch={partyDispatch} notificationsDispatch={notificationsDispatch}/>

                <ToastContainer/>

                {/*Full screen alerts*/}

                {(isMobileOnly) && props.isLandscape && (window.orientation && window.orientation !== 0 && window.orientation !== 180) &&
                <BlockFullScreenAlert>
                    <FormattedMessage description="Alert message mobile landscape"
                                      defaultMessage="Dispositivo in orizzontale? mmh... mettilo in verticale, YAK funzionerà meglio! 🔄"/>
                </BlockFullScreenAlert>}

                {/*NOTE: Portrait on tablet seems to work fine, in case remove the comment in the future. */}
                {/*{isTablet && props.isPortrait &&*/}
                {/*<BlockFullScreenAlert>*/}
                {/*    <FormattedMessage description="Alert message mobile landscape"*/}
                {/*                      defaultMessage="Dispositivo in orizzontale? mmh... mettilo in verticale, YAK funzionerà meglio! 🔄"/>*/}
                {/*</BlockFullScreenAlert>}*/}

                {isIE &&
                <BlockFullScreenAlert>
                    <FormattedMessage description="Alert message of IE"
                                      defaultMessage="IE non è supportato... è tempo di passare ad un browser più moderno? 😊"/>
                </BlockFullScreenAlert>}


                {/* Blocking modals. They will be visible only if they need to be*/}
                {/* TODO: make it lazy loading only if rendering */}
                <PreMatchModal />


                <Suspense fallback={<></>}>
                    {userState && !mmState.active && partyState.party && <PartyModal/>}
                    {userState && mmState?.unableToStart && <MMUnableToStart/>}
                </Suspense>
            </Router>
            </IosPullToReloadWrapper>
            {!isMobileApp && (
                <Helmet>
                    <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript"
                            charSet="UTF-8" data-domain-script="adbed7d5-3fe0-4f52-9f26-6912c893ecb8"/>
                    {/*<script type="text/javascript">*/}
                    {/*    function OptanonWrapper() {}*/}
                    {/*</script>*/}
                </Helmet>
            )}

        </IntlProvider>
    );
}

export default withOrientationChange(App);
