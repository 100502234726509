import * as React from "react";

function SvgUtente(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Utente_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Utente_svg__cls-1"
        d="M492.28 215.92c0 3-.11 6-.37 8.9-1.38 17.26-6.78 32.89-14.83 45.23-7.94 12.12-18.48 21.07-30.45 25.2a.38.38 0 01-.32.11q-31 8.89-62.91 15.25a54.49 54.49 0 01-9.64-61.06c4.61-9.42 16.15-26.16 24.15-33.94 1.75-1.7 3.28-3.66 5.14-5.41A192.5 192.5 0 01421 194.58c14.24-10.58 35.8-21.58 48.72-24.94a182.9 182.9 0 01-4 28.75A181.83 181.83 0 00488 186a104.81 104.81 0 014.28 29.92zM532.42 315.11a120.54 120.54 0 01-55.92 101.95 41.57 41.57 0 01-4.13 2.54 49.57 49.57 0 01-23.83 6H309.47c2.7-11.18 5.66-22.24 8.89-33.31 1.81-6.31 3.66-12.61 5.67-18.86.05-.1.05-.26.11-.37.37-1.16 1.22-3.76 1.22-3.76A53.69 53.69 0 01361.1 336a28 28 0 013.07-.74c1.06-.16 2.07-.37 3.13-.58q21.29-3.82 42.15-8.8 26-6 51.21-13.82c23.36-7 46.24-15.2 68.64-24.25a119.69 119.69 0 013.12 27.3z"
      />
    </svg>
  );
}

export default SvgUtente;
