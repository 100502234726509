import * as React from "react";

function SvgLocked(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Locked_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Locked_svg__cls-1"
        d="M530.66 294.32v-1.57a41.79 41.79 0 00-30.07-38.58v-26.69a57.84 57.84 0 00-57.8-57.84h-43.64a57.84 57.84 0 00-57.8 57.84v26.69a41.57 41.57 0 00-27.22 25l17.79 17.8 1.91 1.91a14.18 14.18 0 009.05 3.2h117a14.16 14.16 0 019.19 3.34c.05 0 .05 0 .1.05l.1.1.1.09.15.15.14.15c.4.44.79.83 1.18 1.23L498.72 335l35.58 35.59zm-62.95-41.82h-93.53v-25a25 25 0 0115.58-23.15 24.3 24.3 0 019.44-1.86h43.49a24.3 24.3 0 019.44 1.86 25 25 0 0115.58 23.15z"
      />
      <path
        className="Locked_svg__cls-1"
        d="M487.22 355h-91.36a14.18 14.18 0 01-9.05-3.2l-1.91-1.92-1.38-1.37-15.28-15.29-8.95-8.94h91.36a14.48 14.48 0 019 3.19c.19.2.39.35.54.5l1 1 1.13 1.13z"
      />
      <path
        className="Locked_svg__cls-1"
        d="M536.9 425.64H305l5.6-118.54 25.4 25.41L376.49 373a14.51 14.51 0 0010.32 4.28h116.82a14.48 14.48 0 019 3.19c.19.2.39.35.54.49l1 1a3.6 3.6 0 01.44.49l21.28 21.23z"
      />
    </svg>
  );
}

export default SvgLocked;
