import * as React from "react";

function SvgDoubleDownArrow(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Double_down_arrow_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Double_down_arrow_svg__cls-1"
        d="M538.78 277.94l-101.17 101.2a.31.31 0 00-.16.16A16.2 16.2 0 01426 384h-30.48a16.27 16.27 0 01-11.42-4.68.31.31 0 00-.16-.16L282.78 277.94H339a17.17 17.17 0 0111.82 4.72 5.45 5.45 0 01.68.68l55.85 55.81 3.42 3.42 3.37-3.42 55.46-55.42 1.43-1.43a17.29 17.29 0 0111.46-4.36z"
      />
      <path
        className="Double_down_arrow_svg__cls-1"
        d="M492.59 211.29L427.93 276a.17.17 0 00-.1.1 10.4 10.4 0 01-7.31 3H401a10.44 10.44 0 01-7.31-3 .17.17 0 00-.1-.1L329 211.29h36a11 11 0 017.55 3 3.61 3.61 0 01.44.43l35.7 35.68 2.18 2.18 2.15-2.18 35.37-35.4.91-.92a11.09 11.09 0 017.33-2.79z"
      />
    </svg>
  );
}

export default SvgDoubleDownArrow;
