import * as React from "react";

function SvgRimuoviAmico(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Rimuovi_amico_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Rimuovi_amico_svg__cls-1"
        d="M536 406.67a20.3 20.3 0 01-31.24 3.13L489.94 395l-5.19-5.18-19.43 19.48c-.15.15-.3.27-.47.42a19.56 19.56 0 01-8.42 4.84 20.29 20.29 0 01-22.43-8.38l33.63-33.56-32.27-32.24a20.51 20.51 0 016.85-6.41 20.22 20.22 0 0124.43 3.26l18.19 18.21L503 337.23a21.63 21.63 0 014.12-3.21 21.07 21.07 0 014.66-2c.15 0 .25-.08.4-.1a21 21 0 015.11-.67 20.57 20.57 0 0117 9.11L505.53 369l-3.6 3.58zM468.41 215.92c0 3-.11 6-.37 8.9-1.38 17.26-6.78 32.89-14.83 45.23-7.95 12.12-18.49 21.07-30.46 25.2a.37.37 0 01-.32.11q-31 8.89-62.92 15.25a54.49 54.49 0 01-9.64-61.06c4.61-9.42 16.15-26.16 24.15-33.94 1.75-1.7 3.29-3.66 5.14-5.41a193.85 193.85 0 0118-15.62c14.24-10.59 35.8-21.55 48.73-24.94a181.76 181.76 0 01-4 28.75A182 182 0 00464.17 186a104.81 104.81 0 014.24 29.92z"
      />
      <path
        className="Rimuovi_amico_svg__cls-1"
        d="M508.56 315.11c0 1.34 0 2.66-.08 4a2.74 2.74 0 01-.39.1 34.2 34.2 0 00-14.6 8.61l-8.71 8.74-8.78-8.79a33.65 33.65 0 00-51.83 5.23 13.33 13.33 0 001.71 16.82l22.78 22.79-24.17 24.1a13.38 13.38 0 00-1.71 16.83 33.84 33.84 0 0012 11.05 48.82 48.82 0 01-10.13 1H285.57c2.7-11.17 5.66-22.24 8.91-33.31 1.78-6.31 3.65-12.61 5.66-18.87.05-.09.05-.24.1-.37l1.21-3.75A53.74 53.74 0 01337.22 336a23.82 23.82 0 013-.75c1.07-.15 2.09-.37 3.13-.6q21.3-3.79 42.17-8.78 26-6 51.21-13.83c23.38-7 46.24-15.19 68.65-24.25a119.72 119.72 0 013.18 27.32z"
      />
    </svg>
  );
}

export default SvgRimuoviAmico;
