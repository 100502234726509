/**
 *
 * @param $secs
 * @return {Promise<Response>}
 */
export default function toDDHHMMSS($secs) {

    let sec_num = parseInt($secs, 10);
    let days = Math.floor(sec_num / 86400);

    let hours = Math.floor((sec_num / 3600) - (days * 24));

    // let hours = Math.floor((sec_num - (hours * 3600)) / 60);
    // let minutes = Math.floor((sec_num - (hours * 3600)) / 60);

    let minutes = Math.floor((sec_num / 60) - (hours * 60) - (days * 1440));

    let seconds = sec_num - (days * 86400) - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }

    return days + 'd:' + hours + 'h:' + minutes + 'm:' + seconds + 's';
}