import React, {useReducer} from 'react';
import axios from "axios";
import {getApiUrl} from "../utility/apiHelpers";

const initialState = null;

export const UserContext = React.createContext(initialState);

export const UserReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const UserStore = ({children}) => {
    const [state, dispatch] = useReducer(UserReducer, initialState);
    return (
        <UserContext.Provider value={[state, dispatch]}>
            {children}
        </UserContext.Provider>
    )
};

export function dispatchUserUpdated(userDispatch, payload) {
    userDispatch({type: 'UPDATE', payload});
}

export function updateUserInfo(userDispatch) {
    return axios.get(getApiUrl('users/profile'))
        .then(response => {
            dispatchUserUpdated(userDispatch, response.data.data);
        });
}