import VarButton from "./VarButton";

const PrimaryButton = ({children, ...props}) => {
    return (
        <VarButton {...props} className={(props.className || '')+ ' VarButton-linear-bg'}>
            {children}
        </VarButton>
    );
}

export default PrimaryButton;