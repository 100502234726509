import * as React from "react";

function SvgAggiungiAmico(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Aggiungi_amico_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Aggiungi_amico_svg__cls-1"
        d="M565.68 378.63a20.1 20.1 0 01-14.15 5.83h-20.21V424a19.87 19.87 0 01-18.84-5.21 18.34 18.34 0 01-2.18-2.65 26.15 26.15 0 01-1.44-2.34 19.58 19.58 0 01-2.26-9.16v-20.2H467a20 20 0 0119.46-24.75h20.14v-39.54a20 20 0 0118.91 5.31 17.32 17.32 0 012.41 2.91 19.87 19.87 0 013.4 11.24v20.1H571a20.05 20.05 0 01-5.32 18.92zM488.51 215.92c0 3-.11 6-.37 8.9-1.38 17.26-6.78 32.89-14.84 45.23-7.94 12.12-18.48 21.07-30.45 25.2a.38.38 0 01-.32.11q-31 8.89-62.92 15.25A54.44 54.44 0 01367.37 256a52.8 52.8 0 012.6-6.46c4.6-9.42 16.15-26.16 24.15-33.94 1.75-1.7 3.28-3.66 5.14-5.41a193.79 193.79 0 0117.95-15.62C431.46 184 453 173 465.94 169.64a181.74 181.74 0 01-4 28.75A182.7 182.7 0 00484.27 186a105.17 105.17 0 014.24 29.92z"
      />
      <path
        className="Aggiungi_amico_svg__cls-1"
        d="M467 397.84h26.21v2.78a119.16 119.16 0 01-20.5 16.43 38.78 38.78 0 01-4.12 2.56 49.61 49.61 0 01-23.85 6H305.65c2.71-11.17 5.69-22.24 8.91-33.31 1.81-6.31 3.65-12.61 5.66-18.87a3.38 3.38 0 01.13-.37l1.21-3.75A53.68 53.68 0 01357.3 336a24.27 24.27 0 013.08-.75c1.07-.15 2.06-.37 3.13-.6 14.2-2.53 28.22-5.43 42.15-8.78q26-6 51.23-13.83c23.35-7 46.24-15.19 68.65-24.25a117.8 117.8 0 013.05 23.21 33 33 0 00-17.22-4.8 32.51 32.51 0 00-7.92.95 13.33 13.33 0 00-10.23 13v26.21h-6.75A33.34 33.34 0 00454 387.64a13.39 13.39 0 0013 10.2z"
      />
    </svg>
  );
}

export default SvgAggiungiAmico;
