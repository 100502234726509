import React from 'react';
import BaseModal from "./BaseModal";
import {setReady} from "../../contexts/MMContext";
import {toast} from "react-toastify";
import {getServerErrorMessage} from "../../utility/form";
import useMatchmaking from "../../hooks/useMatchmaking";
import Timer from "../Timer";
import PrimaryButton from "../buttons/PrimaryButton";
import {FormattedMessage} from "react-intl";
import groupBy from "../../utility/groupBy";
import useUser from "../../hooks/useUser";
import PartyTripleTopBar from "../PartyTripleTopBar";
import PartyHStackSteps from "../PartyHStackSteps";
import * as Icons from './../icons2'
import './PreMatchModal.css'
import UserAvatarSlot, {UserAvatarSlotsContainer} from "../UserAvatarSlot";


function PlayerAvatar({player}) {
    const [mmState] = useMatchmaking();
    const [user] = useUser();
    return <UserAvatarSlot
        key={player?.id}
        user={user && (player.userId === user.id) ? user : true}
        stack={user && (player.userId === user.id) ? mmState : null}
        overlay={player.isReady ? <>&nbsp;<FormattedMessage description="Ready" defaultMessage="Ready"/>&nbsp;</> : null}
    />
}


const PreMatchModal = (props) => {
    const [mmState, mmDispatch] = useMatchmaking();

    const [user] = useUser();

    const handleReady = () => {
        setReady(mmDispatch)
            .catch(error => toast.error(getServerErrorMessage(error)))
    }

    // Follows the old condition and its simplification. The condition has later been changed to show also with ready.
    // !(!active || to_process || processing || (!(to_process || processing) && ready))  // True condition at this point
    // active && !to_process && !processing && !(!(to_process || processing) && ready)   // by De Morgan
    // active && !to_process && !processing && ((to_process || processing) || !ready)    // by De Morgan
    // active && !to_process && !processing && (to_process || processing || !ready)      // ...logic
    // active && !to_process && !processing && !ready                                    // ...still logic
    // active && (matched || paused) && !ready             // NOTE: matched or paused are the only other possible states
    // (matched || paused) && !ready                       // NOTE: any state like matched or paused imply active==true
    // NOTE: condition changed: will also show with ready.

    const condition_version_1 = mmState.active && mmState.status !== 'to_process' && mmState.status !== 'processing';
    const condition_version_2 = mmState.status === 'matched' || mmState.status === 'paused';
    if (condition_version_1 !== condition_version_2) {
        console.error("Inconsistency while evaluating PreMatchModal show conditions: ", condition_version_1, condition_version_2, mmState)
    }
    if (!condition_version_1 || !condition_version_2) {
        return null;
    }

    const prematchTeams = mmState.players && mmState.players.length > 0 ? groupBy(mmState.players, p => p.prematchTeam) : null;
    const lay = mmState.players.length > 2;

    return (
        <BaseModal onRequestClose={null} shouldCloseOnEsc={false}
                   shouldCloseOnOverlayClick={false} {...props}>
            <div className="PreMatchModal">
                <PartyTripleTopBar party={{arena: {game: {name: mmState?.gameName}}}}/>
                <br/>

                <div className={"content flex justify-around items-center " + (lay ? 'flex-col gap-8' : 'flex-row gap-2 tablet:gap-8')}>
                    {prematchTeams && Array.from(prematchTeams.values()).map((team, tIndex) => (
                        <React.Fragment key={team?.id}>
                            <UserAvatarSlotsContainer lay={lay} style={{minWidth: 0}}
                                                      opponents={!team?.find(p => p?.userId === user?.id)}>
                                {team.map(player => <PlayerAvatar key={player.id} player={player}/>)}
                            </UserAvatarSlotsContainer>
                            {tIndex !== prematchTeams.size - 1 &&
                            <div className="flex-auto text-center">
                                {!mmState.isReady
                                    ?
                                    <PrimaryButton onClick={handleReady} disabled={mmState.isReady} style={{fontSize: "0.9em"}}>
                                        <FormattedMessage description="PreMatchModal - Ready button label" defaultMessage="Ready"/>
                                    </PrimaryButton>
                                    :
                                    <Icons.Matchmaking className="text-secondary-lightest text-6xl unshift"/>
                                }
                            </div>
                            }
                        </React.Fragment>
                    ))}
                </div>

                <br/>

                <div className="content whitespace-nowrap">
                    <div
                        className="mx-auto leading-7 flex-auto flex flex-row items-center rounded-md pl-4 pr-3 text-xs bg-secondary-light"
                        style={{width: '16.5em'}}>
                        <div>{/* Maybe use monospace font family? */}
                            <Timer timestamp={mmState.timestampReadyExpiry} countdown={true}/>
                        </div>
                        <div className="flex-grow text-center">
                            &nbsp;-&nbsp;
                        </div>
                        <div>
                            {mmState.readyUsersCount || 0}/{mmState.maxPlayerNeeded}
                            &nbsp;
                            <Icons.Amici className="text-xl"/>
                        </div>
                    </div>
                </div>
                <br/>

                <PartyHStackSteps party={{arena: {id: mmState?.arenaId}}}/>
            </div>
        </BaseModal>
    );
};

export default PreMatchModal;