import axios from "axios";
import {getServerErrorMessage} from "./form";
import {toast} from "react-toastify";
import useSWR, {SWRConfig} from 'swr';
import {FormattedMessage} from "react-intl";
import {useMemo} from "react";
import {isApiTokenValid} from "./apiToken";

/**
 * Get YAK core url for backend api.
 * @param path The path to append to the base url. DON'T start path with "/"
 * @returns {string}
 */
export function getApiUrl(path = '') {
    return process.env.BACKEND_URL.trimRight('/') + "/api/v1/" + path;
}

/**
 * HTTP GET json data from YAK core resource
 *
 * Usage example:
 * ```
 *     const {data: arenas, reload: reloadArenas} = useCoreApiGet('arenas')
 * ```
 *
 * @param path will be passed to getApiUrl; if null, skip.
 * @param config will be passed to axios.get; must be serializable
 * @return {{data, reload}}
 */
export function useCoreApiGet(path, config) {
    const {data, error, mutate} = useSWR([path, JSON.stringify(config)])
    return {data: data?.data, status: data?.status, error, reload: mutate, mutate}
}

/** Please use consciously as keys are never evicted. Use in conjunction with SWR. */
function useLocalStorageCached(key, value) {
    return useMemo(() => {
        if(!window?.localStorage?.getItem) {
            return value
        }
        if (value) {
            window.localStorage.setItem(key, JSON.stringify(value))
            return value
        }
        return JSON.parse(window.localStorage.getItem(key))
    }, [key, value])
}

/** Shorthand to get all available arenas (with last season). */
export function useCoreApiGetArenas() {
    const obj = useCoreApiGet('arenas', {params: {withLastSeason: 1}});
    const cached = useLocalStorageCached('arenas-data', obj.data)
    return {...obj, data: cached}
}

/** Shorthand to get all available drivers. */
export function useCoreApiGetDrivers() {
    const obj = useCoreApiGet('drivers');
    const cached = useLocalStorageCached('drivers-data', obj.data)
    return {...obj, data: cached}
}

export function useCoreApiGetArenaById(arenaId) {
    const {data: arenas, error, reload, mutate} = useCoreApiGetArenas();
    const arena = useMemo(() => parseInt(arenaId) ? arenas?.find?.(a => a?.id === parseInt(arenaId)) : undefined, [arenas, arenaId]);
    return {data: arena, error, reload, mutate}
}

/** Shorthand to get all open stacks. */
export function useCoreApiGetStacks() {
    const isLogged = isApiTokenValid();
    return useCoreApiGet(isLogged ? 'stacks' : null, {params: {onlyOpen: 1}});
}

/** Shorthand to get the wallet of the user for a currency.
 *  Does not use server-side filter to improve cache hits. */
export function useCoreApiGetWalletByCurrency(currency) {
    const {data, error, reload, mutate} = useCoreApiGet('wallets');
    const wallet = useMemo(() => data?.find?.(w => w?.currency === currency), [data, currency]);
    return {data: wallet, error, reload, mutate}
}

/** Shorthand to get all orgs where I am member. */
export function useCoreApiGetMyOrgs() {
    return useCoreApiGet('organizations', {params: {scopeMine: 1, withMembers: 1}});
}

/** Shorthand to get all user relationships. */
export function useCoreApiGetUserRelationships() {
    const isLogged = isApiTokenValid();
    return useCoreApiGet(isLogged ? 'relationships' : null, {params: {perPage: 9999}});
}

/* NOTE about the above shorthands: they make it easier to track down the most frequent requests,
 * use always the same parameters, cache and optimize them, leaving room for future optimizations.
 */

/** Use this as a fetcher for SWR */
export function coreApiGetFetcher(path, cfg) {
    if (!path) {
        return null;
    }
    const url = getApiUrl(path)
    const config = cfg ? JSON.parse(cfg) : {};
    // Let's put a default pagination option...  FIXME: not very nice.
    const longConfig = config.params?.perPage ? config : {...config, params: {...config?.params, perPage: 1000}};
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            axios.get(url, longConfig)
                .then(res => resolve(res.data))
                .catch(reject)
        }, 50)
        // NOTE: We introduce an artificial delay for three reasons:
        //  - make it evident when a buggy behaviour verifies in case of true network delays
        //  - make it evident when a cached value is immediately displayed
        //  - defer the request to decrease the CPU load of the rendering loop on mobile
        // The delay is not really necessary and can be zero. This is to set the implementation up for testing purpose.
    });
}

const ourSWRConfigValue = {
    provider: () => new Map(),
    fetcher: coreApiGetFetcher,
    dedupingInterval: 2000,   // NOTE: this is the default; it should be short, but long enough to catch slow renders.
    revalidateIfStale: false, // NOTE: false is bad if useCoreApiGet is expected to always fetch, not bad if a call to reload()/mutate() is expected.
    revalidateOnFocus: false, // NOTE: very optional, no choice should be bad.
    revalidateOnMount: true,  // NOTE: with false, it seems it will neither fetch on first load.
    onError: (error, key) => {
        console.error('API', key, error);
        let errorMessage = getServerErrorMessage(error);
        // console.log(errorMessage);

        if (!!error.isAxiosError && !error.response) {
            //Network Error --> put just one toast at the bottom (no dupes)
            toast.error(errorMessage, {
                toastId: "NetworkError",
                position: "bottom-right",
                hideProgressBar: true,
                autoClose: 10000,
            });
        }
        else {
            toast.error(errorMessage);
        }

        return null;
    }
    // NOTE: SWR uses the exponential backoff algorithm to retry the request on error.
}

/** Delegates to SWR by Vercel */
export function CoreApiConfig({children}) {
    return <SWRConfig value={ourSWRConfigValue}>{children}</SWRConfig>
}

export function CoreApiErrorMessageSpan({error}) {
    const errorMessage = error ? (typeof error?.message === 'string' ? error?.message : getServerErrorMessage(error)) : null;
    return <span className="ErrorMessageSpan">
        {errorMessage ?? <FormattedMessage
            description={"CoreApiErrorMessageSpan"}
            defaultMessage={"Si è verificato un errore. Riprovare più tardi."}/>}
        </span>
}
