import React from 'react';
import PrimaryButton from "../buttons/PrimaryButton";
import {FormattedMessage} from "react-intl";
import {acceptPartyInvitation, refusePartyInvitation} from "../../utility/party";
import {deleteNotification, updateNotifications} from "../../contexts/NotificationsContext";
import {toast} from "react-toastify";
import {getServerErrorMessage} from "../../utility/form";
import {useHistory} from "react-router-dom";
import {useNotificationsDispatch} from "../../hooks/useNotifications";

const PartyInvitationToast = ({closeToast, toastProps, notification}) => {
    const history = useHistory();
    const notificationsDispatch = useNotificationsDispatch();

    const handleAccept = () => {
        acceptPartyInvitation(notification.party_id, notification.id, notification.token)
            .then(() => {
                closeToast();
                deleteNotification(notificationsDispatch, notification.id);
            })
            .catch(error => {
                updateNotifications(notificationsDispatch)
                    .catch(e => toast.error(getServerErrorMessage(e)));
                toast.error(getServerErrorMessage(error));
                //here we redirect to the accept page in case of error because the user may not have an open stack (in that page he/she can)
                history.push(`/parties/${notification.party_id}/accept-invitation?n=${notification.id}&t=${notification.token}`);
            });
    }

    const handleRefuse = () => {
        refusePartyInvitation(notification.party_id, notification.id, notification.token)
            .then(() => {
                closeToast();
                deleteNotification(notificationsDispatch, notification.id);
            })
            .catch(error => {
                updateNotifications(notificationsDispatch)
                    .catch(e => toast.error(getServerErrorMessage(e)));
                toast.error(getServerErrorMessage(error));
            })
    }

    return (
        <div className="text-black">
            <p className="mb-4">
                <FormattedMessage description="Party invitation proposal"
                                  defaultMessage="{invitingUsername} ti sta invitando a unirti al suo Party"
                                  values={{
                                      invitingUsername: notification.inviting_username,
                                  }}/>
            </p>
            <div className="flex flex-row flex-wrap justify-end items-center gap-4">
                <button onClick={handleRefuse} className="font-bold">
                    <FormattedMessage description="Refuse" defaultMessage="Rifiuta"/>
                </button>
                <PrimaryButton onClick={handleAccept} className="btn-md">
                    <FormattedMessage description="Accept" defaultMessage="Accetta"/>
                </PrimaryButton>
            </div>
        </div>
    );
};

export default PartyInvitationToast;