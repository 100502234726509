import * as React from "react";

function SvgAggiungiFondi(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Aggiungi_fondi_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Aggiungi_fondi_svg__cls-1"
        d="M462.32 205.16H337.26l110.39-35A4.28 4.28 0 01453 173zM497.4 205.16h-22l-7-22.36 17.19-3.09a4.27 4.27 0 014.82 2.93zM531.43 290.86v10.87c0 5.89-5.27 10.84-12.25 11.45a9.72 9.72 0 01-1.32.06h-49.69v-23.19c0-6.33 6.14-11.51 13.63-11.51h37.38a12.34 12.34 0 0112.25 12.32z"
      />
      <path
        className="Aggiungi_fondi_svg__cls-1"
        d="M519.25 226.75V265h-37.44c-15 0-27.21 11.26-27.21 25.06v36.73H519v11.39a46.53 46.53 0 00-8.81-2.38h-.07c-.54-.13-1.09-.2-1.67-.29s-1.19-.2-1.8-.26a40.74 40.74 0 00-5.76-.39h-.22a52.29 52.29 0 00-50.57 38.94H291.52a8.75 8.75 0 01-8.74-8.74V252.48A34.48 34.48 0 01317.26 218H510.5a8.76 8.76 0 018.75 8.75z"
      />
      <path
        className="Aggiungi_fondi_svg__cls-1"
        d="M519 354.12a36.71 36.71 0 00-13-4.21 7 7 0 00-1.16-.2 28.18 28.18 0 00-4-.25A37.64 37.64 0 00485 352.9a37.11 37.11 0 00-7.62 4.66 38.22 38.22 0 00-12 16.24 36.87 36.87 0 00-1.93 7.05 33.62 33.62 0 00-.61 6.53 36.71 36.71 0 00.32 4.63A37.92 37.92 0 10519 354.12zm5.73 36.6a10 10 0 01-7.21 3h-10.39v20.23a10.2 10.2 0 01-9.59-2.67c-.42-.48-.84-1-1.13-1.35s-.48-.77-.7-1.25a9.67 9.67 0 01-1.19-4.63v-10.37h-20.17a10.3 10.3 0 012.7-9.65 10.23 10.23 0 017.24-3h10.23v-20.12a9.79 9.79 0 016.69.67 8.53 8.53 0 013 2c.25.25.48.51.7.77a10.27 10.27 0 012.26 6.5v10.22h20.2a10.1 10.1 0 01-2.68 9.65z"
      />
    </svg>
  );
}

export default SvgAggiungiFondi;
