import * as React from "react";

function SvgSegnalaUtente(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Segnala_utente_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Segnala_utente_svg__cls-1"
        d="M472.35 215.93c0 3-.11 6-.37 8.9-1.38 17.27-6.78 32.9-14.83 45.24-7.95 12.13-18.49 21.08-30.46 25.21a.38.38 0 01-.32.11q-31 8.89-62.93 15.25a54.62 54.62 0 01-9.64-61.07c4.61-9.43 16.16-26.17 24.16-33.95 1.74-1.7 3.28-3.66 5.13-5.41a193.85 193.85 0 0118-15.62c14.25-10.6 35.81-21.56 48.73-25a181.86 181.86 0 01-4 28.76A184 184 0 00468.11 186a104.81 104.81 0 014.24 29.93zM512.51 315.14a121.46 121.46 0 01-6.73 39.9c-.24.71-.49 1.41-.76 2.12l-8.13-13.11-7.13-11.46a20.44 20.44 0 00-34.81-.07l-51.59 83.18-.21.32a13.48 13.48 0 00-.76 1.35 10.36 10.36 0 00-.66 1 20.52 20.52 0 00-2.79 7.36H289.49c2.71-11.17 5.66-22.25 8.91-33.32 1.79-6.31 3.65-12.61 5.66-18.87.05-.1.05-.25.1-.37l1.22-3.75A53.76 53.76 0 01341.13 336a27.06 27.06 0 013.08-.74c1.06-.15 2.06-.37 3.13-.6 14.2-2.53 28.22-5.43 42.15-8.79q26-6 51.24-13.82c23.36-7 46.23-15.2 68.65-24.26a119.72 119.72 0 013.13 27.35z"
      />
      <path
        className="Segnala_utente_svg__cls-1"
        d="M531.08 425.72l-34.61-55.78-11.47-18.5-7.18-11.54a6.43 6.43 0 00-11 0l-7.22 11.64-46 74.16a6.47 6.47 0 005.51 9.88h106.45a6.48 6.48 0 005.52-9.86zm-58.74.52a7.15 7.15 0 117.15-7.15 7.15 7.15 0 01-7.15 7.15zm9.58-64.42l-5 41.33a4.58 4.58 0 01-4.54 4 4.53 4.53 0 01-4.52-4l-5-41.33a4.53 4.53 0 014.52-5.09h10.1a4.55 4.55 0 014.44 5.09z"
      />
    </svg>
  );
}

export default SvgSegnalaUtente;
