import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {MMStore} from "./contexts/MMContext";
import {PartyStore} from "./contexts/PartyContext";
import {UserStore} from "./contexts/UserContext";
import {NotificationsStore} from "./contexts/NotificationsContext";
import {ThemeStore} from "./contexts/ThemeContext";
import {CoreApiConfig} from "./utility/apiHelpers";
import {FlexSearchStore} from "./contexts/FlexSearchContext";
import ReactGA from "react-ga4";
import {ErrorBoundary} from "./ErrorBoundary";

// init locale
async function loadLocaleData(locale) {
    switch (locale) {
        case 'it-IT':
            return await import('./langs/it.json')
        default:
            return await import('./langs/en.json')
    }
}

async function bootstrapApplication(locale, mainDiv) {
    ReactGA.initialize("G-68B6GLXQ5S");
    ReactGA.send("pageview");

    const messages = await loadLocaleData(locale)

    ReactDOM.render((
            <React.StrictMode>
                <ErrorBoundary>
                <CoreApiConfig>
                <FlexSearchStore>
                <UserStore>
                    <MMStore>
                            <PartyStore>
                                <NotificationsStore>
                                    <ThemeStore>
                                        <App locale={locale} messages={messages}/>
                                    </ThemeStore>
                                </NotificationsStore>
                            </PartyStore>
                    </MMStore>
                </UserStore>
                </FlexSearchStore>
                </CoreApiConfig>
                </ErrorBoundary>
            </React.StrictMode>
        )
        , mainDiv)
}

(async () => {
    await bootstrapApplication("it-IT", document.getElementById('root'));
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
