import VarButton from "./VarButton";

const SecondaryButton = ({children, ...props}) => {
    return (
        <VarButton {...props} className={(props.className || '')+ ' VarButton-secondary'}>
            {children}
        </VarButton>
    );
}

export default SecondaryButton;