/**
 * Get the error message from the axios error exception
 * @param error
 * @returns {*}
 */
export const getServerErrorMessage = error => {
    let message = error.message;
    if (error.response) {
        if (error.response.status === 422) {
            let messages = Object.entries(error.response.data).map(([k, msgArray]) => msgArray.join('; '));
            message = messages.join("\n");
        } else if (error.response.data.message) {
            message = error.response.data.message;
        }
    }
    return message;
}

export function setErrorsFromApiResponse(responseData, setError, clearErrors = null) {
    clearErrors && clearErrors();
    Object.keys(responseData).forEach((fieldName) => {
        setError(fieldName, {
            type: 'server',
            message: responseData[fieldName].join('; '),
        });
    });
}