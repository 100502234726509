import * as React from "react";

function SvgAmateur(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Amateur_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Amateur_svg__cls-1"
        d="M388 288.21v19.08a5.84 5.84 0 003 5.1l19.51 10.81 19.83-11a5.22 5.22 0 002.68-4.55V288.1a5.84 5.84 0 00-2.91-5.05l-19.6-11.31L391 283a6 6 0 00-3 5.21z"
      />
      <path
        className="Amateur_svg__cls-1"
        d="M538.53 302a28.31 28.31 0 01-1.47 5.83 29.45 29.45 0 01-2.25 4.88l-49.48 85.7a30.19 30.19 0 01-26.17 15.09h-98.58a30.17 30.17 0 01-26.42-15.5l-31.56-56.94h32.54L348.92 366a36.5 36.5 0 0032 18.79h58a36.45 36.45 0 0031.6-18.24l29.22-50.58a36.86 36.86 0 003.43-8.05h-27.99v17.67a16.81 16.81 0 01-8.66 14.69l-1.53.83-46 25.5a16.82 16.82 0 01-16.25 0l-41.12-22.8a1.44 1.44 0 01.7-2.7h48.55A16.73 16.73 0 00419 339l23.24-12.87a16.7 16.7 0 008.19-10.89 16.51 16.51 0 00.44-3.79v-26.68a16.53 16.53 0 00-.46-3.87h83.47l.91 1.59a30.38 30.38 0 013.56 9.73 30 30 0 01.18 9.78z"
      />
      <path
        className="Amateur_svg__cls-1"
        d="M518.68 254.6h-33.3l-14.9-25.83a36.45 36.45 0 00-31.6-18.24h-58a36.54 36.54 0 00-32 18.82l-28 50.6a36.1 36.1 0 00-3.36 8.32h29v-17.43a16.75 16.75 0 018.38-14.52l2.24-1.29.74-.43 44.57-25.73a16.75 16.75 0 0116.77 0l39.91 23a1.44 1.44 0 01-.72 2.68h-47.53a16.2 16.2 0 00-3.74.43 17.23 17.23 0 00-4.65 1.82l-23.24 13.41a16.73 16.73 0 00-7.92 10.64 17.16 17.16 0 00-.46 3.88v3.49l.45 26.92h-83.07l-1.62-2.91a30.31 30.31 0 010-29.29l47.53-85.68a30.21 30.21 0 0126.42-15.58h98.59a30.22 30.22 0 0126.17 15.09z"
      />
    </svg>
  );
}

export default SvgAmateur;
