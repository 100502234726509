import * as React from "react";

function SvgClessidra(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Clessidra_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Clessidra_svg__cls-1"
        d="M494.54 169.63v15.43c0 7.45-6.6 13.5-14.76 13.5H359.33v33c0 12.22 6.57 23.22 17 30.82 11.86 8.64 19 20.64 19 33.48v3.46c0 12.84-7.16 24.84-19 33.48-10.44 7.6-17 18.61-17 30.82a8.66 8.66 0 01-8.67 8.64h-22.92v-8.64c0-21.13 12.2-40 31.24-52.21a10.07 10.07 0 001.35-.84c4.57-3.11 7.47-7.79 7.47-13s-3-10-7.66-13.1c-.25-.15-.53-.34-.81-.49-19.24-12.25-31.59-31.17-31.59-52.43v-48.42c0-7.45 6.63-13.5 14.79-13.5z"
      />
      <path
        className="Clessidra_svg__cls-1"
        d="M494.54 396.71v15.42c0 7.45-6.63 13.5-14.79 13.5h-152V410.2c0-7.45 6.6-13.49 14.76-13.49H463v-33c0-12.21-6.57-23.22-17-30.82-11.86-8.64-19-20.64-19-33.48v-3.51c0-12.84 7.16-24.84 19-33.48 10.44-7.6 17-18.6 17-30.82a8.65 8.65 0 018.66-8.64h22.93v8.64c0 21.13-12.2 40-31.25 52.21a13.58 13.58 0 00-1.34.84c-4.57 3.12-7.48 7.79-7.48 13s3 10 7.67 13.12c.25.15.53.34.81.5 19.24 12.25 31.59 31.17 31.59 52.42v33z"
      />
    </svg>
  );
}

export default SvgClessidra;
