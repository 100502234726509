import * as React from "react";

function SvgNotifica(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Notifica_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Notifica_svg__cls-1"
        d="M548.94 335a37.45 37.45 0 01-37.45 37.45H292.94a37.43 37.43 0 0130.93-36.86v-34.88c0-25 7.28-56.41 19.63-74.86 13.94-20.93 34.33-36 57.79-41.49a4.05 4.05 0 00.64-.17c1.26-.3 2.56-.55 3.83-.8q5.49-1.14 11-2a240.71 240.71 0 0174.61-.47l-11.42 24a101.14 101.14 0 0118.41 21c11.88 17.82 19.13 48.28 19.59 72.21V335zM443.38 417.05h-24.82a25.5 25.5 0 01-6-.71 25.26 25.26 0 01-19.25-24.1h25.23a24.74 24.74 0 0121.57 12.6 23.3 23.3 0 013 9 19.93 19.93 0 01.27 3.21z"
      />
    </svg>
  );
}

export default SvgNotifica;
