import {useContext} from "react";
import {NotificationsDispatchContext, NotificationsStateContext} from "../contexts/NotificationsContext";

export function useNotifications() {
    return [useContext(NotificationsStateContext), useContext(NotificationsDispatchContext)];
}
export function useNotificationsState() {
    return useContext(NotificationsStateContext);
}
export function useNotificationsDispatch() {
    return useContext(NotificationsDispatchContext);
}