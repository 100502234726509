import * as React from "react";

function SvgCerca(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      id=""
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M548.94 410.44a43 43 0 01-55.69-4.32l-42.86-42.86-27.61-27.61A64.23 64.23 0 00449.5 311a63.4 63.4 0 009-32.38c.19-35-28.89-64.29-63.93-64.29-35.28 0-63.39 28.66-63.88 63.93-.46 33.54 25.57 57.18 49.69 62.28a35.28 35.28 0 0117.48 9.78l25.45 25.46a101.9 101.9 0 01-39.31 3.63c-48.11-5-86.75-44.29-90.71-92.5a101.7 101.7 0 01112-109.76c46.78 4.77 84.74 42.07 90.28 88.76a101.61 101.61 0 01-18.83 72.37l12.18 12.19z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCerca;
