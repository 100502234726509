import UAParser from 'ua-parser-js';

const DeviceTypes = {
    MobileApp: 'app', //this is a custom type, not part of ua-parser
    AndroidMobileApp: 'android-app', //this is a custom type, not part of ua-parser
    IOSMobileApp: 'ios-app', //this is a custom type, not part of ua-parser
    Mobile: 'mobile',
    Tablet: 'tablet',
    SmartTv: 'smarttv',
    Console: 'console',
    Wearable: 'wearable',
    Embedded: 'embedded',
    Browser: undefined
};

const checkDeviceType = deviceType => {
    const initialData = {
        isMobileApp: false,
        isAndroidApp: false,
        isIOSApp: false,
        isMobile: false,
        isTablet: false,
        isBrowser: false,
        isSmartTV: false,
        isConsole: false,
        isWearable: false,
        isEmbedded: false,
        deviceType: deviceType || 'desktop',
    };
    switch (deviceType) {
        case DeviceTypes.MobileApp:
            return {...initialData, isMobileApp: true };
        case DeviceTypes.AndroidMobileApp:
            return {...initialData, isAndroidApp: true, isMobileApp: true };
        case DeviceTypes.IOSMobileApp:
            return {...initialData, isIOSApp: true, isMobileApp: true };
        case DeviceTypes.Mobile:
            return {...initialData, isMobile: true };
        case DeviceTypes.Tablet:
            return {...initialData, isTablet: true };
        case DeviceTypes.SmartTv:
            return {...initialData, isSmartTV: true };
        case DeviceTypes.Console:
            return {...initialData, isConsole: true };
        case DeviceTypes.Wearable:
            return {...initialData, isWearable: true };
        case DeviceTypes.Embedded:
            return {...initialData, isEmbedded: true };
        case DeviceTypes.Browser:
            return {...initialData, isBrowser: true };
        default:
            return initialData;
    }
};

export function getUADevice(userAgent) {
    let deviceType;
    if (checkMobileApp(userAgent)) {
        if (checkAndroidApp(userAgent)) {
            deviceType = 'android-app';
        } else if (checkIOSApp(userAgent)) {
            deviceType = 'ios-app';
        } else {
            deviceType = 'app'; //this is for backwards compatibility
        }
    } else {
        deviceType = (new UAParser(userAgent))?.getDevice()?.type ?? undefined;
    }

    return checkDeviceType(deviceType);
}

export function checkMobileApp(userAgent) {
    return /^yak-games-mobile-app/.test(userAgent);
}

export function checkAndroidApp(userAgent) {
    return userAgent === 'yak-games-mobile-app-android';
}

export function checkIOSApp(userAgent) {
    return userAgent === 'yak-games-mobile-app-ios';
}