import * as React from "react";

function SvgModificaImmagine(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Modifica_immagine_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Modifica_immagine_svg__cls-1"
        d="M509.6 263.66a1 1 0 011.74.69v112.1a46.78 46.78 0 01-16.27 35.79 56.55 56.55 0 01-37.2 13.4H338.62a56.57 56.57 0 01-37.2-13.4 46.8 46.8 0 01-16.26-35.79v-125a51.41 51.41 0 0114-35.59c9.54-10 22.81-15.57 37.39-15.57h115.23a1 1 0 01.72 1.67l-18.45 17.64a29.59 29.59 0 01-20.46 8.11h-73.22c-13.72 0-25.08 9.78-26.53 22.78V372.8c0 14.11 12 25.55 26.69 25.55H456c14.74 0 26.69-11.44 26.69-25.55v-71.89a27.06 27.06 0 018.47-19.57zM531.2 215.36L521 225.55l-33.2-33.19 22.73-22.72 20.67 20.66a17.71 17.71 0 010 25.06z"
      />
      <path
        className="Modifica_immagine_svg__cls-1"
        d="M531.2 215.36L521 225.55l-33.2-33.19 22.73-22.72 20.67 20.66a17.71 17.71 0 010 25.06zM508.29 238.27l-32 32-4.18 4.17-58.61 58.6a22.84 22.84 0 01-7.63 5l-33.13 9a5 5 0 01-6.49-6.49l9-33.11a22.68 22.68 0 015-7.64l94.8-94.8z"
      />
    </svg>
  );
}

export default SvgModificaImmagine;
