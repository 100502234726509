import * as React from "react";

function SvgMobile(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Mobile_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Mobile_svg__cls-1"
        d="M478.11 277.21v110.58a16.77 16.77 0 01-16.8 16.75h-119.1V348.4l-19.81 19.16v58h145.75a29.71 29.71 0 0029.77-29.68V258zM352.18 169.58a29.73 29.73 0 00-29.78 29.67V343l8.91-8.63 10.9-10.53v-115.7A16.78 16.78 0 01359 191.38h119.11v61.08l19.81-19.16v-63.72z"
      />
      <path
        className="Mobile_svg__cls-1"
        d="M458.38 212.66a6 6 0 016 6v157.42a6.93 6.93 0 01-6.94 6.92H355.93V219.26a6.62 6.62 0 016.63-6.6h10.85l9.67 9.64a14.43 14.43 0 0010.18 4.21h33.54a14.44 14.44 0 0010.19-4.2l9.67-9.65z"
      />
    </svg>
  );
}

export default SvgMobile;
