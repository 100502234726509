import * as React from "react";

function SvgPro(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="210 149 421 298"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <style>{".Pro_svg__st0{fill:#ffb400}"}</style>
      <path
        className="Pro_svg__st0"
        d="M256 70.8c0 81.1-87.8 127.6-168.5 127.6-21.1 0-40.7-2.9-59.9-8.7 2.9-18.2 9.4-35.1 19.8-49.4 3.7.2 7.5.3 11.2.3 2.3 0 4.6 0 6.9-.1 43.1-1.4 82.9-15.8 115.7-39.4 7.3-5.3 14.3-11 20.9-17.2 2.5-2.2 4.9-4.6 7.2-7.1 1.6-1.7 3.2-3.5 4.6-5.3 11.5-14.1 19.4-31.1 22.6-49.8.6-3.6 1.1-7.3 1.3-11.1C250.5 29 256 49.5 256 70.8z"
      />
      <path
        className="Pro_svg__st0"
        d="M200.4 69.4c-9.7 9.5-20.4 18.1-31.8 25.6-8 5.2-16.3 9.9-25 14-25.6 12.2-54.1 19.3-84.1 20.2-1.1 0-2.2.1-3.3.1.6-.7 1.3-1.4 1.9-2.1.6-.6 1.1-1.2 1.7-1.8 5-4.7 10.3-9.1 15.7-13.2 4.8-3.7 9.8-7.1 14.9-10.4 32-20.2 69.6-32 110-32.4z"
      />
      <path
        className="Pro_svg__st0"
        d="M.7 127.9C.7 46.8 88.4.2 169.1.2c21.1 0 40.7 2.9 59.9 8.7-2.9 18.2-9.4 35.1-19.8 49.4-3.7-.2-7.5-.3-11.2-.3-2.3 0-4.6 0-6.9.1-43.1 1.4-82.9 15.8-115.7 39.4-7.3 5.3-14.3 11-20.9 17.2-2.5 2.2-4.9 4.6-7.2 7.1-1.6 1.7-3.2 3.5-4.6 5.3-11.5 14.1-19.4 31.1-22.6 49.8-.6 3.6-1.1 7.3-1.3 11.1C6.2 169.7.7 149.2.7 127.9z"
      />
    </svg>
  );
}

export default SvgPro;
