import React from 'react';
import PrimaryButton from "../buttons/PrimaryButton";
import {FormattedMessage} from "react-intl";
import {getServerErrorMessage} from "../../utility/form";
import {toast} from "react-toastify";
import {useNotificationsDispatch} from "../../hooks/useNotifications";
import {deleteNotification, updateNotifications} from "../../contexts/NotificationsContext";
import {confirmOrganizationEntryRequest, refuseOrganizationEntryRequest} from "../../utility/organization";
import {Link} from "react-router-dom";

const OrganizationEntryRequestToast = ({closeToast, toastProps, notification}) => {
    const notificationsDispatch = useNotificationsDispatch();
    const handleConfirm = () => {
        confirmOrganizationEntryRequest(notification.organization_id, notification.id, notification.token)
            .then(() => {
                deleteNotification(notificationsDispatch, notification.id);
                closeToast();
            })
            .catch(error => {
                updateNotifications(notificationsDispatch)
                    .catch(e => toast.error(getServerErrorMessage(e)));
                toast.error(getServerErrorMessage(error));
            });
    }

    const handleRefuse = () => {
        refuseOrganizationEntryRequest(notification.organization_id, notification.id, notification.token)
            .then(() => {
                deleteNotification(notificationsDispatch, notification.id);
                closeToast();
            })
            .catch(error => {
                updateNotifications(notificationsDispatch)
                    .catch(e => toast.error(getServerErrorMessage(e)));
                toast.error(getServerErrorMessage(error));
            });
    }

    return (
        <div className="text-black">
            <p className="mb-2 break-words w-fit-content">
                <FormattedMessage description="Organization entry request notification message"
                                  defaultMessage={"{requestingUsername} vuole unirsi alla tua organizzazione."}
                                  values={{requestingUsername: notification.requesting_username}}/>
            </p>
            <p className="mb-4 break-words w-fit-content">
                <Link to={"/users/"+notification.requesting_user_id} className="font-bold text-yellow hover:text-yellow-dark cursor-pointer">
                    <FormattedMessage description="See who is the user who wants to join the organization"
                                      defaultMessage={"Scopri chi è {requestingUsername}"}
                                      values={{requestingUsername: notification.requesting_username}}/>
                </Link>
            </p>
            <div className="flex flex-row flex-wrap justify-end items-center gap-4">
                <button onClick={handleRefuse} className="font-bold">
                    <FormattedMessage description="Refuse" defaultMessage="Rifiuta"/>
                </button>
                <PrimaryButton onClick={handleConfirm} className="btn-md">
                    <FormattedMessage description="Accept" defaultMessage="Accetta"/>
                </PrimaryButton>
            </div>
        </div>
    );
};

export default OrganizationEntryRequestToast;