import * as React from "react";

function SvgCestino(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Cestino_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Cestino_svg__cls-1"
        d="M481.09 203.37v-8.66a7.63 7.63 0 00-7.62-7.63H370.28a7.64 7.64 0 00-7.63 7.63v8.66h-34.91A21.59 21.59 0 00306.15 225v11.9h212a19.44 19.44 0 0019.44-19.43v-14.1zM349.05 251.84a28.25 28.25 0 00-28.17 30.47l12.59 160.77h148a28.21 28.21 0 0028.12-25.93L523 251.84zm33.12 166.5l-5.92.36-7.43-128.46a10.26 10.26 0 019.41-11l6.27-.39L391.91 407a10.63 10.63 0 01-9.74 11.34zm46.46-10.58a10.56 10.56 0 01-10.56 10.56h-5.75V289.45a10.2 10.2 0 0110.2-10.2h6.11zm37.24.28a10.5 10.5 0 01-11.11 9.96l-5.91-.45L458 289.17a10.14 10.14 0 0110.73-9.59l6.26.47z"
      />
    </svg>
  );
}

export default SvgCestino;
