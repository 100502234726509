import React from "react";
import {Loading} from "./icons2";
import './Animation.css';

const Spinner = ({className, ...props}) => {
    return (
        // <Loading className={"block mx-auto animate-spin " + (className || '')} {...props} />
        <Loading className={"animate-spin dkr-animation rotating sec-3 infinity " + (className || '')} {...props} />
    );
}

export default Spinner;