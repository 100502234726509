import React, {useReducer} from 'react';
import axios from "axios";
import {getApiUrl} from "../utility/apiHelpers";

const initialState = {
    party: null,
};

export const PartyContext = React.createContext(initialState);

export const PartyReducer = (state, action) => {
    switch (action.type) {
        case 'CREATE':
            return {
                ...state,
                party: action.payload.party,
            };
        case 'LEAVE':
            return {
                ...initialState,
            };
        case 'UPDATE':
            return {
                ...state,
                party: action.payload.party,
            };
        case 'ADD_INVITATION':
            let invitations = Array.from(state.party.invitations);
            invitations.push(action.payload);
            return {...state, party: {...state.party, invitations: [...invitations]}};
        case 'DELETE_INVITATION':
            state.party.invitations.splice(state.party.invitations.findIndex(invitation => invitation.notificationId === action.payload), 1);
            return {...state}
        default:
            return state;
    }
};

export const PartyStore = ({children}) => {
    const [state, dispatch] = useReducer(PartyReducer, initialState);
    return (
        <PartyContext.Provider value={[state, dispatch]}>
            {children}
        </PartyContext.Provider>
    )
};

export function createParty(partyDispatch, arenaId) {
    return axios.post(getApiUrl('party'), {arena_id: arenaId})
        .then(response => {
            partyDispatch({type: 'CREATE', payload: {party: response.data.data}});
        })
}

export function leaveParty(partyDispatch) {
    partyDispatch({type: 'LEAVE'});
}
export function updateParty(partyDispatch, payload) {
    partyDispatch({type: 'UPDATE', payload});
}
export function updatePartyStatus(partyDispatch) {
    return axios.get(getApiUrl('party'))
        .then(statusResponse => {
            updateParty(partyDispatch, {party: statusResponse.data.data});
        });
}
export function addPartyInvitation(partyDispatch, partyInvitation) {
    partyDispatch({type: 'ADD_INVITATION', payload: partyInvitation});
}
export function deletePartyInvitation(partyDispatch, notificationId) {
    partyDispatch({type: 'DELETE_INVITATION', payload: notificationId});
}
