import * as React from "react";

function SvgMedia(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Media_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Media_svg__cls-1"
        d="M530.11 320.94a12.55 12.55 0 018.55-3.69v14.83l-.06 21.74v5a29 29 0 01-11.5 23.37 21.73 21.73 0 01-2.71 1.82 29 29 0 01-14.86 4.08H328a8.11 8.11 0 01-8.13-8.1v-26.14l52.76-52.77a15.06 15.06 0 0121.3 0l58.41 58.42a27.78 27.78 0 0039.27 0z"
      />
      <path
        className="Media_svg__cls-1"
        d="M530.65 243.85H344.33a24.53 24.53 0 00-24.51 24.53v64.15L364.37 288a27 27 0 0138.14 0l59 59a15.06 15.06 0 0021.3 0l40-39.94a23.14 23.14 0 0116-6.82l.09-48.21a8.14 8.14 0 00-8.25-8.18zm-52.44 63.39a18.49 18.49 0 1118.49-18.49 18.48 18.48 0 01-18.49 18.49z"
      />
      <path fill="none" d="M445.57 371.01l.12.12h-.27" />
      <path
        className="Media_svg__cls-1"
        d="M480.55 207.15a21.14 21.14 0 0121.15 21.18H341.2a37.4 37.4 0 00-37.4 37.4v88.09a21 21 0 01-21-21v-69.29c0-29.81 21.43-56.38 54-56.38z"
      />
    </svg>
  );
}

export default SvgMedia;
