import * as React from "react";

function SvgStack2(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Stack_2_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Stack_2_svg__cls-1"
        d="M435.6 205.49v136.8l-24.89 32.94h-.06l-24.9-32.94V221.14l27.86-27.86v-23.64a39.62 39.62 0 0110.18 7.36 40.22 40.22 0 0111.81 28.49zM473.06 239.82v55.47l-24.16 32V249.1l10.26-10.25v-17.71a19.65 19.65 0 0113.9 18.68z"
      />
      <path
        className="Stack_2_svg__cls-1"
        d="M502.82 260h-16.47v26.78H490a2.3 2.3 0 011.83 3.69l-79.2 105.15a2.32 2.32 0 01-3.69 0l-79.23-105.19a2.3 2.3 0 011.83-3.69h40.93V260h-53.74a21.77 21.77 0 00-17.38 34.87L393.4 417a21.77 21.77 0 0034.77 0l92-122.14A21.77 21.77 0 00502.82 260z"
      />
    </svg>
  );
}

export default SvgStack2;
