import * as React from "react";

function SvgProtest(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Protest_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Protest_svg__cls-1"
        d="M608 192.16a9.15 9.15 0 01-.29 13.21l-26.81 24.55a7.38 7.38 0 01-10.2-.47 7.38 7.38 0 01-.47-10.2l24.56-26.8a9.14 9.14 0 0113.21-.29zM608 362a9.15 9.15 0 00-.29-13.21l-26.81-24.56a7.39 7.39 0 00-10.2.47 7.4 7.4 0 00-.47 10.21l24.56 26.8A9.15 9.15 0 00608 362zM533.41 189.31v185.48a19.72 19.72 0 01-29.49 17.12l-61.63-35.24a10 10 0 01-5.06-8.71V216.14a10 10 0 015.06-8.71l61.63-35.24a19.72 19.72 0 0129.49 17.12z"
      />
      <path
        className="Protest_svg__cls-1"
        d="M283.32 254.34H301a3.31 3.31 0 013.31 3.31V323h-17.68a3.31 3.31 0 01-3.31-3.31v-65.3-.05z"
        transform="rotate(-180 293.815 288.65)"
      />
      <path
        className="Protest_svg__cls-1"
        d="M646.74 278.58A11.34 11.34 0 01635 289.92l-66-2.09c-6.52-.2-11.66-4.28-11.66-9.25 0-5 5.14-9 11.66-9.24l66-2.09a11.34 11.34 0 0111.7 11.33zM390.84 368s-25.94 37.37-33.42 50.37a14.43 14.43 0 01-12.52 7.23h-37.46l32.84-50.14a14.47 14.47 0 0112.66-7.46zM412.81 221.22v97.83a28 28 0 01-8.26 19.95 28.05 28.05 0 01-19.87 8.22h-56.86v-97.78A28.21 28.21 0 01356 221.22z"
      />
    </svg>
  );
}

export default SvgProtest;
