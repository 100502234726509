import GhostHeader from "../header/GhostHeader";
import Modal from "react-modal";
import React from "react";
import './BaseModal.css'

/**
 * BaseModal alternative because you may need a way out of some transformation applied to the caller...
 * react-modal appends stuff at the end of the document for that.
 */
export default function AbsoluteModal({children, box, borderIsTheNewBg}) {
    // WILL NOT useLockBodyScroll(true);
    return <Modal overlayClassName="BaseModalOverlay" className="BaseModalContainer" isOpen={true}>
        <GhostHeader/>
        {/* leave room also for secondary header */}
        <div style={{height: 24}}>&nbsp;</div>
        {(box || box === undefined)
            ? <div className={"BaseModalContainerBox "+box+(borderIsTheNewBg ? ' borderIsTheNewBg' : '')}>{children}</div>
            : children}
    </Modal>
}