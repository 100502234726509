import * as React from "react";

function SvgDoubleLeftArrow(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Double_left_arrow_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Double_left_arrow_svg__cls-1"
        d="M430.47 425.64l-101.2-101.16a.31.31 0 00-.16-.16 16.28 16.28 0 01-4.68-11.43v-30.51a16.31 16.31 0 014.68-11.38.31.31 0 00.16-.16l101.2-101.16v56.25a17.13 17.13 0 01-4.72 11.82 5.41 5.41 0 01-.67.68l-55.82 55.86-3.41 3.41 3.41 3.37 55.42 55.46 1.43 1.43a17.24 17.24 0 014.36 11.46z"
      />
      <path
        className="Double_left_arrow_svg__cls-1"
        d="M497.12 379.45l-64.68-64.66a.21.21 0 00-.11-.1 10.38 10.38 0 01-3-7.3v-19.5a10.46 10.46 0 013-7.31.21.21 0 00.11-.1l64.68-64.66v36a11 11 0 01-3 7.56 4.36 4.36 0 01-.43.43L458 295.47l-2.18 2.18 2.18 2.16 35.43 35.44.91.92a11 11 0 012.79 7.32z"
      />
    </svg>
  );
}

export default SvgDoubleLeftArrow;
