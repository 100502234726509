import React, {useEffect, useState} from 'react';
import toHHMMSS from "../utility/timeActions";
import PropTypes from 'prop-types';
import moment from "moment";

const Timer = ({timestamp, countdown}) => {
    const [timerSec, setTimerSec] = useState(0);

    useEffect(() => {
        const now = moment.utc().unix();
        const initialValue = countdown ? timestamp - now : now - timestamp;
        setTimerSec(initialValue);

        const timerInterval = setInterval(() => {
            if (countdown) {
                setTimerSec((prev) => prev > 0 ? prev - 1 : 0);
            } else {
                setTimerSec((prev) => prev + 1);
            }
        }, 1000);

        return () => {
            clearInterval(timerInterval);
            setTimerSec(0);
        };

    }, [timestamp, countdown]);

    return (
        <>
            {toHHMMSS(timerSec)}
        </>
    );
};

Timer.propTypes = {
    timestamp: PropTypes.number,
    countdown: PropTypes.bool,
}

Timer.defaultProps = {
    countdown: false,
}

export default Timer;