import React, {createContext, useContext, useMemo} from "react";
import {renderToString} from "react-dom/server";
import useFaqs from "../hooks/useFaqs";
import {useCoreApiGetArenas, useCoreApiGetDrivers} from "../utility/apiHelpers";

import {Document} from "flexsearch";

export const FlexSearchContext = createContext(null);

export function FlexSearchStore({children}) {
    const faqs = useFaqs();
    const {data: arenas} = useCoreApiGetArenas();
    const {data: drivers} = useCoreApiGetDrivers();

    const faqsIndex = useMemo(() => {

        const _index = new Document({
            encoder: 'advanced',
            tokenize: 'full',
            document: {
                id: "id",
                index: ["title", "html"],
                store: ["title", "details"],
            }
        });

        faqs.forEach((faq, i) => _index.addAsync({
            id: i,
            html: renderToString(faq.details),
            ...faq
        }))

        return _index;

    }, [faqs]);


    const arenasIndex = useMemo(() => {
        if (!(arenas?.length > 0)) {
            return new Document({document: {id: "id"}});
        }
        const _index = new Document({
            encoder: 'extra',
            tokenize: 'full',
            document: {
                id: "id",
                index: ["name", "gameName"],
                store: ["data"],
            }
        });
        arenas.forEach(arena => _index.addAsync({
            id: arena?.id,
            name: arena?.name,
            gameName: arena?.game?.name,
            data: arena,
        }))
        return _index;
    }, [arenas]);


    const driversIndex = useMemo(() => {
        if (!(drivers?.length > 0)) {
            return new Document({document: {id: "id"}});
        }
        const _index = new Document({
            encoder: 'extra',
            tokenize: 'full',
            document: {
                id: "id",
                index: ["name"],
                store: ["data"],
            }
        });
        drivers.forEach(driver => _index.addAsync({
            id: driver?.id,
            name: driver?.name,
            data: driver,
        }))
        return _index;
    }, [drivers]);

    const contextValue = {
        faqsIndex,
        arenasIndex,
        driversIndex,
    };

    // console.debug('FlexSearchContext', contextValue)

    return <FlexSearchContext.Provider value={contextValue}>
        {children}
    </FlexSearchContext.Provider>
}

/**
 * @return {{faqsIndex: Document, arenasIndex: Document, driversIndex: Document}|null} search indexes
 */
export function useFlexSearch() {
    return useContext(FlexSearchContext);
}

/**
 * @param {Document|null} index
 * @param {string} keyword
 * @param {int?} limit
 */
export async function searchAsyncSimple(index, keyword, limit) {
    if (!index) {
        return []
    }
    const all = await index.searchAsync(keyword, limit, {enrich: true});
    const flat = all?.flatMap?.(rr => rr?.result)
    const ids = new Set();
    const unique = [];
    flat?.forEach(r => {
        if (!ids.has(r?.id)) {
            unique?.push(r)
        }
    })
    return unique;
}
