import * as React from "react";

function SvgConferma(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      id=""
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M548.94 226a45.49 45.49 0 01-13.39 32.3L385.43 408.37l-79.14-79.14a45.6 45.6 0 018.6-71.23l62.69 62.63 7.85 7.85 78.83-78.88L527 186.91A45.57 45.57 0 01548.94 226z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgConferma;
