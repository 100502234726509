import * as React from "react";

function SvgScroll(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      id=""
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M393.89 169.64a57.29 57.29 0 00-57.3 57.25v198.75H448a57.31 57.31 0 0057.3-57.3v-198.7zm17.68 69.61a11.06 11.06 0 0111-11.06h7.73v75a11.08 11.08 0 01-11.07 11.11h-7.68zm20.63 137.26c-.05 0-.05 0-.05.09a6.85 6.85 0 01-4.78 1.93h-12.8a6.67 6.67 0 01-4.78-1.93l-.1-.09-18.12-18.12-24.3-24.3h23.63a7.16 7.16 0 014.92 2 1.32 1.32 0 01.29.29l2.66 2.66 20.77 20.72 1.41 1.45 25.26-25.27a7.5 7.5 0 014.83-1.83h23.58z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgScroll;
