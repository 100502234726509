import * as React from "react";

function SvgSettings(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Settings_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Settings_svg__cls-1"
        d="M531.88 230.51l-55.28 55.11a56.5 56.5 0 00-29.38-38.71A57.11 57.11 0 00380.79 257l-64 63.82a.9.9 0 01-1.45-1 30.15 30.15 0 00-22.4-42.82 129.59 129.59 0 0113-39.35c.1-.24.27-.47.37-.71a30.14 30.14 0 0042.43-17.35 30 30 0 00-4-26.85 129.28 129.28 0 0137.84-18.88 30.27 30.27 0 0058.51-4.23 130 130 0 0139.6 13.05l.48.27a29 29 0 00-1.63 3.93 30.14 30.14 0 0019.17 38.16 30.45 30.45 0 0026.89-3.9c2.23 3.03 4.34 6.15 6.28 9.37z"
      />
      <path
        className="Settings_svg__cls-1"
        d="M310 364.77l55.28-55.11a56.46 56.46 0 0029.38 38.7 57.11 57.11 0 0066.43-10.1l64-63.81a.89.89 0 011.45 1 30.15 30.15 0 0022.38 42.81 129.38 129.38 0 01-13 39.35c-.1.23-.27.47-.37.71a30.08 30.08 0 00-42.43 17.35 30 30 0 004 26.84 129.28 129.28 0 01-37.84 18.88 30 30 0 00-1.66-4A30.32 30.32 0 00417 403.91a30 30 0 00-16.22 21.73 130.26 130.26 0 01-39.6-13.05l-.48-.27a30.13 30.13 0 00-17.54-42.1 30.48 30.48 0 00-26.89 3.9c-2.21-3.02-4.27-6.12-6.27-9.35z"
      />
      <path
        className="Settings_svg__cls-1"
        d="M442.94 319.17a31.23 31.23 0 01-36.34 5.59 31 31 0 1141.92-13.18 31.29 31.29 0 01-5.58 7.59z"
      />
    </svg>
  );
}

export default SvgSettings;
