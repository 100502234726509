import {useEffect, useState} from "react";
import {isApiTokenValid} from "../utility/apiToken";

const NO_WS_BASE_PERIOD = 10_000
const NO_WS_PERIOD_JITTER = 250
const NO_WS_PHASE_JITTER = 1_750

// Result: the update is at most delayed by NO_WS_BASE_PERIOD + NO_WS_PERIOD_JITTER + NO_WS_PHASE_JITTER milliseconds

/** This hook does simply return a number that is periodically incremented. That's it.
 * You can supply this value as a dependency to a useEffect that does data fetching.
 * It is meant as an emergency fallback in case no ws service is available.
 * It has two peculiar features:
 *  - it's not exactly periodic, to avoid a periodic load on the server, which could generate resonance effects.
 *  - there's no increment when connected (window?.Echo?.connector?.socket?.connected === true)
 */
export function useNoWsAutoRefresher() {
    const [noWsAutoRefresher, setNoWsAutoRefresher] = useState(0);

    useEffect(() => {
        const period = NO_WS_BASE_PERIOD + Math.random() * NO_WS_PERIOD_JITTER  // Avoid the same period for everybody

        const t = setInterval(() => {
            setTimeout(() => {

                if (window?.Echo?.connector?.pusher?.connection?.state !== 'connected' && isApiTokenValid()) {
                    // Do it in any case, unless we are connected for sure
                    setNoWsAutoRefresher(v => v + 1)
                    console.warn('WEBSOCKETS seem to be failing - REFRESH triggered.')
                }

            }, Math.random() * NO_WS_PHASE_JITTER)  // Add some jitter in the phase too
        }, period)

        return () => clearInterval(t)
    }, [])

    return noWsAutoRefresher
}