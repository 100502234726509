import React from 'react';
import PrimaryButton from "../buttons/PrimaryButton";
import {FormattedMessage} from "react-intl";
import {getServerErrorMessage} from "../../utility/form";
import {toast} from "react-toastify";
import {confirmNoShow, refuseNoShow} from "../../utility/match";
import {useNotificationsDispatch} from "../../hooks/useNotifications";
import {deleteNotification, updateNotifications} from "../../contexts/NotificationsContext";

const NoShowWarningToast = ({closeToast, toastProps, notification}) => {
    const notificationsDispatch = useNotificationsDispatch();
    const handleConfirm = () => {
        confirmNoShow(notification.match_id, notification.id, notification.token, notification.target_type)
            .then(() => {
                deleteNotification(notificationsDispatch, notification.id);
                closeToast();
            })
            .catch(error => {
                updateNotifications(notificationsDispatch)
                    .catch(e => toast.error(getServerErrorMessage(e)));
                toast.error(getServerErrorMessage(error));
            });
    }

    const handleRefuse = () => {
        refuseNoShow(notification.match_id, notification.id, notification.token, notification.target_type)
            .then(() => {
                deleteNotification(notificationsDispatch, notification.id);
                closeToast();
            })
            .catch(error => {
                updateNotifications(notificationsDispatch)
                    .catch(e => toast.error(getServerErrorMessage(e)));
                toast.error(getServerErrorMessage(error));
            });
    }

    return (
        <div className="text-black">
            <p className="mb-4">
                <FormattedMessage description="Match no-show warning message"
                                  defaultMessage={
                                      "Sembra che tu non ti sia presentatə alla partita nell'arena {arenaName}.{br}" +
                                      "Di' agli altri giocatori cosa intendi fare"
                                  }
                                  values={{
                                      arenaName: notification.arena_name,
                                      br: <br/>,
                                  }}/>
            </p>
            <div className="flex flex-row flex-wrap justify-end items-center gap-4">
                <button onClick={handleConfirm} className="font-bold">
                    <FormattedMessage description="I won't play" defaultMessage="Non giocherò"/>
                </button>
                <PrimaryButton onClick={handleRefuse} className="btn-md">
                    <FormattedMessage description="I will play" defaultMessage="Giocherò"/>
                </PrimaryButton>
            </div>
        </div>
    );
};

export default NoShowWarningToast;