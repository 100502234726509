import React from 'react';
import PrimaryButton from "../buttons/PrimaryButton";
import {FormattedMessage} from "react-intl";
import {acceptPartyInvitation, refusePartyInvitation} from "../../utility/party";
import {toast} from "react-toastify";
import {getServerErrorMessage} from "../../utility/form";
import {useHistory} from "react-router-dom";
import {deleteNotification, updateNotifications} from "../../contexts/NotificationsContext";
import SecondaryButton from "../buttons/SecondaryButton";

const PartyInvitationNotification = ({notification, notificationsDispatch}) => {
    const history = useHistory();

    const handleAccept = () => {
        acceptPartyInvitation(notification.data.party_id, notification.id, notification.data.token)
            .then(() => {
                deleteNotification(notificationsDispatch, notification.id);
            })
            .catch(error => {
                updateNotifications(notificationsDispatch)
                    .catch(e => toast.error(getServerErrorMessage(e)));
                toast.error(getServerErrorMessage(error));
                //here we redirect to the accept page in case of error because the user may not have an open stack (in that page he/she can)
                history.push(`/parties/${notification.data.party_id}/accept-invitation?n=${notification.id}&t=${notification.data.token}`);
            });
    }

    const handleRefuse = () => {
        refusePartyInvitation(notification.data.party_id, notification.id, notification.data.token)
            .then(() => {
                deleteNotification(notificationsDispatch, notification.id);
            })
            .catch(error => {
                updateNotifications(notificationsDispatch)
                    .catch(e => toast.error(getServerErrorMessage(e)));
                toast.error(getServerErrorMessage(error))
            });
    }

    return (
        <div>
            <p className="mb-2 break-words w-fit-content">
                <FormattedMessage description="Party invitation proposal"
                                  defaultMessage="{invitingUsername} ti sta invitando a unirti al suo Party"
                                  values={{
                                      invitingUsername: notification.data.inviting_username,
                                  }}/>
            </p>
            <div className="flex flex-row flex-wrap justify-start items-center gap-4">
                <SecondaryButton onClick={handleRefuse} className="btn-sm">
                    <FormattedMessage description="Refuse" defaultMessage="Rifiuta"/>
                </SecondaryButton>
                <PrimaryButton onClick={handleAccept} className="btn-sm">
                    <FormattedMessage description="Accept" defaultMessage="Accetta"/>
                </PrimaryButton>
            </div>
        </div>
    );
};

export default PartyInvitationNotification;