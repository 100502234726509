import * as React from "react";

function SvgNoShow(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".No_Show_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="No_Show_svg__cls-1"
        d="M352.46 334.94l-50 58.39c.05-.16.1-.32.16-.48 1.8-6.31 3.65-12.62 5.67-18.88.05-.1.05-.26.11-.37.37-1.16 1.22-3.76 1.22-3.76a53.73 53.73 0 0135.79-33.41 30.75 30.75 0 013.08-.75c1.06-.16 2.07-.37 3.13-.58zM517 315.53a120.69 120.69 0 01-56 102.09 42.39 42.39 0 01-4.13 2.55 49.7 49.7 0 01-23.87 6h-63l106-123.83c12.78-4.4 25.4-9.18 37.92-14.21a120 120 0 013.08 27.4zM516.28 232.11l-67.89 79.28-98.21 114.82-27.35 31.87c-13.21-12.83-16.81-32.72-10.34-49.42a44.17 44.17 0 017.74-13.05l55.93-65.34 22.33-26 78.22-91.43 37-43.17c16.88 16.41 18.05 44.41 2.57 62.44zM474.17 192.71L374 309.7l-3.16 3.7a.56.56 0 01-.82 0l-2.33-2.4a54.27 54.27 0 01-15-37.54 53.7 53.7 0 012.76-17.13 52.31 52.31 0 012.6-6.47c4.61-9.44 16.17-26.2 24.18-34 1.75-1.69 3.29-3.66 5.14-5.41a195.85 195.85 0 0118-15.64c14.27-10.61 35.85-21.58 48.79-25a183.53 183.53 0 01-4 28.8 183.68 183.68 0 0022.32-12.41c.68 2.16 1.21 4.33 1.69 6.51z"
      />
    </svg>
  );
}

export default SvgNoShow;
