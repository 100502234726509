import {FormattedMessage} from "react-intl";
import React, {useState} from "react";
import {deleteNotification} from "../../contexts/NotificationsContext";
import SecondaryButton from "../buttons/SecondaryButton";
import axios from "axios";
import {getApiUrl} from "../../utility/apiHelpers";
import {toast} from "react-toastify";
import Spinner from "../Spinner";
import {getServerErrorMessage} from "../../utility/form";

const ProfileCompletedNotification = ({notification, notificationsDispatch}) => {
    const [deleting, setDeleting] = useState(false);

    const handleOk = () => {
        setDeleting(true)
        return axios.delete(getApiUrl(`users/notifications/${notification.id}`))
            .then(() => {
                deleteNotification(notificationsDispatch, notification.id);
            })
            .catch(error => toast.warn(getServerErrorMessage(error)))
            .then(() => setDeleting(false));
    }

    return (
        <div>
            <p className="mb-2 break-words w-fit-content">
                <FormattedMessage description="profile completion rewarded notification message"
                                  defaultMessage={"Hai guadagnato {prize} COIN per aver completato il profilo"}
                                  values={{
                                      prize: notification.data.prize,
                                  }}/>
            </p>
            <div className="flex flex-row flex-wrap justify-start items-center gap-4">
                <SecondaryButton onClick={handleOk} className="btn-sm" disabled={deleting}>
                    {deleting ? <Spinner/> :
                    <FormattedMessage description="Ok" defaultMessage="Ok"/>
                    }
                </SecondaryButton>
            </div>
        </div>
    );
}

export default ProfileCompletedNotification;
