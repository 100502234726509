import React from 'react';

const BlockFullScreenAlert = (props) => (
    <div className="alert-block-style">
        <div className="flex items-center w-screen h-screen">
            <div className="text-center w-screen p-10">{props.children}</div>
        </div>
    </div>
);

export default BlockFullScreenAlert;