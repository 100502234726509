import {lazy} from 'react';

/**
 * authState possibile value:
 * private = solo logato e valido, public = solo se non sei loggato, none = no auth
 *
*/


const routes = [
    {
        path: '/',
        exact: true,
        component: lazy(() => import('./pages/Home')),
    },
    {
        path: '/shop',
        component: lazy(() => import('./pages/Shop')),
    },
    {
        path: '/play-now',
        component: lazy(() => import('./pages/PlayNow')),
        exact: true,
    },
    {
        path: '/play-now/:game',
        component: lazy(() => import('./pages/PlayNow')),
        exact: true,
    },
    {
        path: '/how-it-works/:preSelectPart?',
        component: lazy(() => import('./pages/HowItWorks')),
    },
    {
        path: '/leaderboard/:arenaId/:userName?',
        component: lazy(() => import('./pages/Leaderboards')),
    },
    {
        path: '/regulation/:seasonId',
        component: lazy(() => import('./pages/Regulation')),
        exact: true,
    },
    {
        path: '/users/:id/confirm-email-change',
        component: lazy(() => import('./pages/ConfirmEmailChange')),
        // exact: true,
    },
    {
        path: '/users/:user',
        exact: true,
        component: lazy(() => import('./pages/user_profile/UserProfilePage')),
    },
    {
        path: '/organizations/:org',
        exact: true,
        component: lazy(() => import('./pages/org_profile/OrgProfilePage')),
    },
    {
        path: '/organizations/:org/teams/:team',
        exact: true,
        component: lazy(() => import('./pages/org_profile/OrgProfilePage')),
    },
    {
        path: '/dashboard',
        component: lazy(() => import('./pages/Dashboard')),
        authState: "private",
        redirectRoute: "/login"
    },
    {
        path: '/support',
        component: lazy(() => import('./pages/Support')),
        exact: true,
    },
    {
        path: '/general/tos',
        component: lazy(() => import('./pages/Tos')),
        exact: true,
    },
    {
        path: '/general/privacy',
        component: lazy(() => import('./pages/Privacy')),
        exact: true,
    },
    {
        path: '/login',
        component: lazy(() => import('./pages/Login')),
        authState: "public",
        redirectRoute: "/"
    },
    {
        path: '/subscribe/3',
        component: lazy(() => import('./pages/Subscribe3')),
        authState: "private",
        redirectRoute: "/",
        exact: true
    },
    {
        path: '/subscribe/2',
        component: lazy(() => import('./pages/Subscribe2')),
        authState: "public",
        redirectRoute: "/",
        exact: true
    },
    {
        path: '/subscribe',
        component: lazy(() => import('./pages/Subscribe')),
        authState: "public",
        redirectRoute: "/",
        exact: true
    },
    {
        path: '/forgot-password',
        component: lazy(() => import('./pages/ForgotPassword')),
        authState: "public",
        redirectRoute: "/",
        exact: true
    },
    {
        path: '/forgot-password/2',
        component: lazy(() => import('./pages/ForgotPassword2')),
        authState: "public",
        redirectRoute: "/"
    },
    {
        path: '/500',
        component: lazy(() => import('./pages/Error500'))
    },
    {
        path: '/parties/:id/accept-invitation',
        component: lazy(() => import('./pages/AcceptPartyInvitation')),
    },
    {
        path: '/parties/:id/refuse-invitation',
        component: lazy(() => import('./pages/RefusePartyInvitation')),
    },
    {
        path: '/parties/:id/private-invitation',
        component: lazy(() => import('./pages/AcceptPrivatePartyInvitation')),
    },
    {
        path: '/matches/:id/confirm-no-show',
        component: lazy(() => import('./pages/ConfirmNoShowWarning')),
    },
    {
        path: '/matches/:id/refuse-no-show',
        component: lazy(() => import('./pages/RefuseNoShowWarning')),
    },
    {
        path: '/matches/:id',
        component: lazy(() => import('./pages/match/Match')),
        authState: 'private',
        redirectRoute: "/login"
    },
    {
        path: '/organizations/:id/accept-entry-request',
        component: lazy(() => import('./pages/AcceptOrganizationEntryRequest')),
    },
    {
        path: '/organizations/:id/refuse-entry-request',
        component: lazy(() => import('./pages/RefuseOrganizationEntryRequest')),
    },
    {
        path: '/promo/:promoToken',
        component: lazy(() => import('./pages/RedeemPromoEvent')),
    },
    {
        path: '/mttester/:what',
        component: lazy(() => import('./pages/MtTester'))
    },
    {
        path: '/mttester',
        component: lazy(() => import('./pages/MtTester'))
    },
    {
        path: '*',
        component: lazy(() => import('./pages/Error404'))
    }
];

export default routes;
