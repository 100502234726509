import * as React from "react";

function SvgTeam(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>
          {
            ".Team_svg__cls-1{fill:#ffb400}.Team_svg__cls-2{fill:#efac18;opacity:.59}"
          }
        </style>
      </defs>
      <path
        className="Team_svg__cls-1"
        d="M456.94 248.6c0 1.82-.06 3.61-.22 5.37a58.58 58.58 0 01-9 27.29c-4.72 7.32-11.1 12.74-18.32 15.21a.23.23 0 01-.19.07q-18.7 5.35-38 9.2a32.73 32.73 0 01-9-22.63 32.93 32.93 0 013.23-14.22c2.78-5.68 9.74-15.78 14.57-20.48 1.05-1 2-2.2 3.1-3.26a118.1 118.1 0 0110.83-9.43c8.6-6.39 21.6-13 29.4-15.05a109.55 109.55 0 01-2.4 17.35 110.19 110.19 0 0013.46-7.47 63.39 63.39 0 012.54 18.05zM481.17 308.46A72.75 72.75 0 01447.42 370a22.78 22.78 0 01-2.49 1.53 29.84 29.84 0 01-14.38 3.65h-83.92c1.63-6.75 3.42-13.42 5.37-20.1 1.09-3.81 2.2-7.61 3.42-11.38 0-.06 0-.16.06-.22l.74-2.27a32.4 32.4 0 0121.57-20.13 16.1 16.1 0 011.85-.45c.64-.1 1.25-.22 1.89-.35 8.56-1.54 17-3.29 25.43-5.31q15.68-3.65 30.91-8.34c14.09-4.25 27.89-9.17 41.41-14.63a71.82 71.82 0 011.89 16.46z"
      />
      <path
        className="Team_svg__cls-2"
        d="M548.94 285.83a54.42 54.42 0 01-25.24 46.05 15.71 15.71 0 01-1.85 1.15 22.34 22.34 0 01-10.77 2.72H487.3a84.37 84.37 0 002.81-44.3l3.3-.76q11.73-2.73 23.13-6.23 15.83-4.8 31-11a54 54 0 011.4 12.37zM375.52 241c0 1.37 0 2.71-.16 4a43.83 43.83 0 01-6.7 20.43c-3.59 5.48-8.35 9.52-13.75 11.39a.18.18 0 01-.15.05q-14 4-28.42 6.88a24.5 24.5 0 01-6.77-16.93 24.17 24.17 0 011.25-7.73 24.69 24.69 0 011.17-2.92 71 71 0 0110.91-15.33c.79-.76 1.48-1.65 2.32-2.44a86.92 86.92 0 018.11-7.06c6.43-4.78 16.17-9.73 22-11.26a81.82 81.82 0 01-1.79 13 82.94 82.94 0 0010.07-5.6 47.58 47.58 0 011.91 13.52zM380.47 309.57c-.28.07-.54.1-.83.16l-.51.1-1.13.17-.51.1a26.17 26.17 0 00-2.78.67 43 43 0 00-27.86 25h-53.91c1.22-5.05 2.56-10 4-15 .8-2.85 1.66-5.72 2.56-8.54 0 0 0-.12.06-.16.16-.54.29-1 .48-1.53a.22.22 0 01.07-.16 24.23 24.23 0 0116.13-15.08c.45-.13.9-.23 1.38-.32l1.43-.29c6.4-1.12 12.76-2.46 19-4q11.75-2.73 23.13-6.23c3.46-1.06 6.91-2.14 10.33-3.33v2a43.31 43.31 0 008.96 26.44zM530.82 241c0 1.37 0 2.71-.16 4a44.05 44.05 0 01-6.7 20.43c-3.59 5.48-8.35 9.52-13.76 11.39a.16.16 0 01-.14.05q-14 4-28.42 6.88a24.5 24.5 0 01-6.77-16.93 24.17 24.17 0 011.25-7.73 23.09 23.09 0 011.17-2.92 70.64 70.64 0 0110.91-15.33c.79-.76 1.48-1.65 2.32-2.44a86.92 86.92 0 018.11-7.06c6.43-4.78 16.17-9.73 22-11.26a83 83 0 01-1.79 13 83.7 83.7 0 0010.07-5.6 47.58 47.58 0 011.91 13.52z"
      />
    </svg>
  );
}

export default SvgTeam;
