import React, {useEffect, useReducer} from 'react';
import axios from "axios";
import {getApiUrl} from "../utility/apiHelpers";
import {toast} from "react-toastify";
import {getServerErrorMessage} from "../utility/form";
import {getSignedInUser} from "../utility/apiToken";
import {notifyMMAction} from "../utility/containerAppBridge";

const initialState = {
    startPending: false,
    unableToStart: false,
    active: false,
    status: null,
    isReady: false,
    timestampStart: null,
    arenaId: null,
    arenaName: null,
    gameName: null,
    gameLogoUrl: null,
    gameLogoOptimizedUrl: null,
    maxPlayerNeeded: null,
    matchedUsersCount: null,
    readyUsersCount: null,
    timestampReadyExpiry: null,
    players: null,
};

export const MMContext = React.createContext(initialState);

export const MMReducer = (state, action) => {
    const userId = getSignedInUser()?.id;
    const isReady = action?.payload?.players?.find?.(m => m?.userId === userId)?.isReady;
    switch (action.type) {
        case 'UNABLE_TO_START':
            return {
                ...initialState,
                ...action.payload,
            };
        case 'START_PENDING':
            return {
                ...state,
                startPending: true,
            };
        case 'START':
            notifyMMAction('started');
            return {
                ...state,
                isReady,
                startPending: false,
                unableToStart: false,
                ...action.payload,
                active:true,
                matchedUsersCount: 1,
            };
        case 'STOP':
            notifyMMAction('stopped');
            return {
                ...initialState,
                active:false,
            };
        case 'SET_STATUS':
            // Please, can we have a more specific action.type?
            if (isReady !== state.isReady) {
                notifyMMAction(isReady ? 'ready' : 'notReady');
            }
            if(
                (action.payload.status === 'matched' || action.payload.status === 'paused') &&
                !(state.status === 'matched' || state.status === 'paused') &&
                !isReady
            ) {
                notifyMMAction('waitingForReady');
            }
            return {
                ...state,
                missingUserDriverCustomFields: false,
                isReady,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const MMStore = ({children}) => {
    const [state, dispatch] = useReducer(MMReducer, initialState);

    // BEGIN BUG PATCH  -- TODO: remove this after the bug is solved
    useEffect(() => {
        if ((state?.status === 'matched' || state?.status === 'paused')
            && state?.players <= 1) {
            // If the player is matched with itself alone, then stop and restart
            // (The condition does only exist in case of bugs).
            console.warn('FAILSAFE MM STOP...')
            const arenaId = state?.arenaId;  // Get the selected arenaId before losing it from the state
            axios.post(getApiUrl('matchmaking/stop'))  // NOTE: not using stopMatchmaking because I then I do not restart in case of stop errors
                .then(() => {
                    dispatchStopMatchmaking(dispatch);
                    if (arenaId) {
                        console.log('FAILSAFE MM RESTART.')
                        return startMatchmaking(dispatch, arenaId)
                    }
                })
                .catch(() => {
                    // error while stopping... failsafe refresh.
                    window?.location?.reload?.()
                })
        }
    }, [state])
    // END BUG PATCH

    return (
        <MMContext.Provider value={[state, dispatch]}>
            {children}
        </MMContext.Provider>
    )
};

export function dispatchMatchmakingStarted(mmDispatch, payload) {
    mmDispatch({type: 'START', payload});
}

export function startMatchmaking(mmDispatch, arenaId) {
    mmDispatch({type: 'START_PENDING'});
    return axios.post(getApiUrl('matchmaking/start'), {arena_id: arenaId}, {
        validateStatus: status => status < 500  // Do not reject 4xx (see axios default behaviour)
    })
        .then(response => {
            if(response.data?.user_driver_custom_fields?.status === 'missing') {
                mmDispatch({
                    type: 'UNABLE_TO_START',
                    payload: {
                        arenaId,
                        unableToStart: {
                            reason: 'missing_user_driver_custom_fields',
                        },
                    }
                });
            }
            else if(response.data?.status === 'nok') {
                if (response.data?.reason === 'already_started') {
                    dispatchMatchmakingStarted(mmDispatch, response.data.data)
                }
                else {
                    mmDispatch({
                        type: 'UNABLE_TO_START',
                        payload: {
                            unableToStart: {
                                message: response.data?.message,
                                ...(response.data?.data),  // includes reason code
                            },
                        }
                    });
                }
            }
            else if(response.status === 201) {
                dispatchMatchmakingStarted(mmDispatch, response.data.data)
            }
            else {
                // eslint-disable-next-line no-throw-literal
                throw {response}
            }
        })
}

export function dispatchStopMatchmaking(mmDispatch) {
    mmDispatch({type: 'STOP'});
}

export function stopMatchmaking(mmDispatch) {
    return axios.post(getApiUrl('matchmaking/stop'))
        .then(() => {
            dispatchStopMatchmaking(mmDispatch);
        })
        .catch(error => {
            toast.error(getServerErrorMessage(error));
        })
}

export function setMatchmakingStatus(mmDispatch, payload) {
    mmDispatch({type: 'SET_STATUS', payload});
}

export function updateMatchmakingStatus(mmDispatch) {
    return axios.get(getApiUrl('matchmaking/status'))
        .then(statusResponse => {
            setMatchmakingStatus(mmDispatch, statusResponse.data.data)
        });
}

export function setReady(mmDispatch) {
    return axios.post(getApiUrl('matchmaking/ready'))
        .then(response => {
            if (response?.data?.status === 'ok') {
                setMatchmakingStatus(mmDispatch, {isReady: true})
            }
            else {
                console.error('POST matchmaking/ready misbehaviour')
            }
        })
}
