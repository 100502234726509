import React from 'react';
import './VarButton.css';


/**
 * Reusable button with some CSS variants
 * Just take a look at the CSS classes VarButton, VarButton-linear-bg, etc... which do all the work.
 *
 * IMPORTANT NOTE:
 *      This component is meant to be reusable
 *      in different contexts and responsive layouts
 *      and is thus sized with relative units (em).
 */
const VarButton = ({children, wrap, ...props}) => {
    return (
        <button type="button" {...props} className={(props.className || '') + (wrap ? ' wrap' : '') + " VarButton"}>
            {children}
        </button>
    );
};

export default VarButton;