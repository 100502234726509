import * as React from "react";

function SvgCorona(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      id=""
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M531.5 233.07a32.8 32.8 0 00-4.13-5.48 19.93 19.93 0 00-3.06-2.83 7.88 7.88 0 00-.83-.59.19.19 0 00-.12-.06L474 265.83l-63.17-64.93c-.05 0-63.85 64.36-63.85 64.36l-48.73-41.21a10.73 10.73 0 00-1.89 1.42 5.43 5.43 0 00-.83.76c-.41.41-.88.83-1.35 1.36s-1 1.12-1.47 1.76l-.3.3a2.91 2.91 0 01-.53.71c-.53.76-1.18 1.7-1.77 2.71-8 13.13-15.37 66.39 12.08 126.25l.12.25a53.11 53.11 0 0043.78 30.66c38.4 3.31 91 3.31 129.43 0a53.16 53.16 0 0043.78-30.64l.12-.27c27.45-59.91 20.03-113.12 12.08-126.25zm-59 128.23c-37.28 3-86.87 3-124.06 0a25.1 25.1 0 01-21.68-16.59c-8.75-24.63-13.27-53.28-15.06-66.77l42 32.58 57.09-61.58 57.12 61.58 41.9-32.52c.06 9.67-7.56 42.74-15.45 66.33a25.09 25.09 0 01-21.82 16.97z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCorona;
