import * as React from "react";

function SvgRimuovi(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      id=""
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M534.64 313.49a48.61 48.61 0 01-34.42 14.3H294.33a48.74 48.74 0 0147.34-60.3h205.89a48.67 48.67 0 01-12.92 46z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgRimuovi;
