import {toast} from "react-toastify";
import {getServerErrorMessage} from "../../utility/form";
import {FormattedMessage} from "react-intl";
import PrimaryButton from "../buttons/PrimaryButton";
import React from "react";
import {confirmNoShow, refuseNoShow} from "../../utility/match";
import {useNotificationsDispatch} from "../../hooks/useNotifications";
import {deleteNotification, updateNotifications} from "../../contexts/NotificationsContext";
import SecondaryButton from "../buttons/SecondaryButton";

const NoShowWarningNotification = ({notification}) => {
    const notificationsDispatch = useNotificationsDispatch();
    const handleConfirm = () => {
        confirmNoShow(notification.data.match_id, notification.id, notification.data.token, notification.data.target_type)
            .then(() => {
                deleteNotification(notificationsDispatch, notification.id);
            })
            .catch(error => {
                updateNotifications(notificationsDispatch)
                    .catch(e => toast.error(getServerErrorMessage(e)));
                toast.error(getServerErrorMessage(error));
            })
    }

    const handleRefuse = () => {
        refuseNoShow(notification.data.match_id, notification.id, notification.data.token, notification.data.target_type)
            .then(() => {
                deleteNotification(notificationsDispatch, notification.id);
            })
            .catch(error => {
                updateNotifications(notificationsDispatch)
                    .catch(e => toast.error(getServerErrorMessage(e)));
                toast.error(getServerErrorMessage(error));
            })
    }

    return (
        <div>
            <p className="mb-2 break-words w-fit-content">
                <FormattedMessage description="Match no-show warning message"
                                  defaultMessage={
                                      "Sembra che tu non ti sia presentatə alla partita nell'arena {arenaName}.{br}" +
                                      "Di' agli altri giocatori cosa intendi fare"
                                  }
                                  values={{
                                      arenaName: notification.data.arena_name,
                                      br: <br/>,
                                  }}/>
            </p>
            <div className="flex flex-row flex-wrap justify-start items-center gap-4">
                <SecondaryButton onClick={handleConfirm} className="btn-sm">
                    <FormattedMessage description="I won't play" defaultMessage="Non giocherò"/>
                </SecondaryButton>
                <PrimaryButton onClick={handleRefuse} className="btn-sm">
                    <FormattedMessage description="I will play" defaultMessage="Giocherò"/>
                </PrimaryButton>
            </div>
        </div>
    );
}

export default NoShowWarningNotification;
