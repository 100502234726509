import axios from "axios";
import {getApiUrl} from "./apiHelpers";

/** @param match
 *  @return {Date} */
export function calcStep2ExpiryDate(match) {
    const d = new Date(match?.created_at);
    d.setSeconds(d.getSeconds() + Math.min(match.arenas[0].result_buttons_seconds, match.arenas[0].no_show_button_seconds));
    return d;
}

/** @param match
 *  @return {Date} */
export function calcResultButtonsTimeoutDate(match) {
    const d = new Date(match?.created_at);
    d.setSeconds(d.getSeconds() + match.arenas[0].result_buttons_seconds);
    return d;
}

/** @param match
 *  @return {Date} */
export function calcNoShowTimeoutDate(match) {
    const d = new Date(match?.created_at);
    d.setSeconds(d.getSeconds() + match.arenas[0].no_show_button_seconds);
    return d;
}

/**
 * setTimeout alternative accepting a vanilla JS Date instead of a number of milliseconds.
 * @param {*} callback
 * @param {Date} date
 */
export function setDateTimeout(callback, date) {
    const delta = date - (new Date());  // milliseconds
    // 2.147.483.647 is the maximum value accepted by setTimeout
    //   604.800.000 is 1 week in milliseconds
    if (delta < 604800000) {
        return setTimeout(callback, delta)
    }
    else {
        // Future is too distant, we will never call back.
        return null  // null is still safe for clearTimeout
    }
}

export function getUserTeamMemberIndex(members, userId) {
    let index = members.findIndex(member => member.user_id === userId);
    return index === -1 ? null : index;
}

export function getUserTeamIndex(teams, userId) {
    let index = teams.findIndex(t => getUserTeamMemberIndex(t.members, userId) !== null);
    return index === -1 ? null : index;
}

export function getUserTeam(teams, userId) {
    return teams?.find?.(t => t?.members?.find?.(u => u?.user_id === userId))
}

export function getUserTeamMember(teams, userId) {
    let userTeam = getUserTeam(teams, userId);

    return userTeam ? getUserTeamMemberIndex(userTeam.members, userId) : null;
}

export function getTeammateNoShowVotes(votes, teammateId, authorUserId = null) {
    return votes.filter(vote => {
        return vote.type === 'no-show'
            && vote.targetable_type === 'App\\Models\\Matches\\MatchTeamMember'
            && vote.targetable_id === teammateId
            && (!authorUserId || (authorUserId && vote.author_user_id === authorUserId));
    });
}

export function getOpponentNoShowVotes(votes, opponentTeamId, authorUserId = null) {
    return votes.filter(vote => {
        return vote.type === 'no-show'
            && vote.targetable_type === 'App\\Models\\Matches\\MatchTeam'
            && vote.targetable_id === opponentTeamId
            && (!authorUserId || (authorUserId && vote.author_user_id === authorUserId));
    });
}

export function getResultVotes(votes, teamId, resultType = null, authorUserId = null) {
    return votes.filter(vote => {
        return vote.type !== 'no-show'
            && vote.targetable_type === 'App\\Models\\Matches\\MatchTeam'
            && vote.targetable_id === teamId
            && (!resultType || (resultType && vote.type === resultType))
            && (!authorUserId || (authorUserId && vote.author_user_id === authorUserId));
    });
}

export function hypothesizeVoteProtestReason(votes, votingTeamId, resultType) {
    if (resultType === 'win') {
        if(votes?.find(vote =>
            vote.type === 'win'
            && vote.targetable_type === 'App\\Models\\Matches\\MatchTeam'
            && vote.targetable_id !== votingTeamId
        )) {
            return 'REASON_TOO_MANY_WINNERS';
        }
    }
    // TODO: keep a consistent logic w.r.t what is implemented by the core.
    return null;
}

/**
 * Move the team of the user in the first place.
 * @param teams
 * @param userId
 * @returns {Array}
 */
export function moveUserTeamFirst(teams, userId) {
    teams.unshift(teams.splice(getUserTeamIndex(teams, userId), 1)[0]);

    return teams
}

export function getUserMedia(matchMedia, uploaderUserId) {
    return matchMedia.filter(media => {
        return uploaderUserId && media.uploader_user_id === uploaderUserId;
    });
}

export function confirmNoShow(matchId, notificationId, notificationToken, targetType) {
    return axios.post(getApiUrl('matches/' + matchId + '/vote/confirm-no-show'), {
        notification_id: notificationId,
        token: notificationToken,
        target_type: targetType,
    })
}

export function refuseNoShow(matchId, notificationId, notificationToken, targetType) {
    return axios.post(getApiUrl('matches/' + matchId + '/vote/refuse-no-show'), {
        notification_id: notificationId,
        token: notificationToken,
        target_type: targetType,
    })
}

export class getConflictingWinVotes {
}