import * as React from "react";

function SvgDoubleRightArrow(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Double_right_arrow_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Double_right_arrow_svg__cls-1"
        d="M391.08 169.64l101.2 101.16a.31.31 0 00.16.16 16.2 16.2 0 014.68 11.42v30.51a16.31 16.31 0 01-4.68 11.43.31.31 0 00-.16.16l-101.2 101.16v-56.26a17.15 17.15 0 014.72-11.82 6 6 0 01.67-.67L452.29 301l3.41-3.41-3.41-3.37-55.42-55.46-1.43-1.43a17.27 17.27 0 01-4.36-11.47z"
      />
      <path
        className="Double_right_arrow_svg__cls-1"
        d="M324.43 215.83l64.69 64.65a.18.18 0 00.1.11 10.36 10.36 0 013 7.3v19.5a10.41 10.41 0 01-3 7.3.19.19 0 00-.1.1l-64.69 64.66V343.5a11 11 0 013-7.56 3.49 3.49 0 01.43-.43l35.68-35.7 2.18-2.18-2.18-2.16L328.13 260l-.91-.91a11.05 11.05 0 01-2.79-7.33z"
      />
    </svg>
  );
}

export default SvgDoubleRightArrow;
