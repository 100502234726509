import * as React from "react";

function SvgScaricaRicevuta(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Scarica_ricevuta_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Scarica_ricevuta_svg__cls-1"
        d="M534.13 349.52l-10.35 10.07L509.07 374l-6.87 6.65a2.25 2.25 0 01-.34.25 6.49 6.49 0 01-4.12 1.43h-8.4a6.67 6.67 0 01-4.15-1.43 1.84 1.84 0 01-.34-.25l-.52-.45L478 374l-4.27-4.19-10.48-10.19-2.1-2.08-8.22-8.06h20.89a6.87 6.87 0 014.76 1.86c.09.09.28.21.4.34l4.31 4.18.73.73v-35.9a6.8 6.8 0 016.78-6.77H503a.1.1 0 010 .06v42.65l5.47-5.34a7.28 7.28 0 014.73-1.77zM538.78 412.8v7.3h-79.27a11.22 11.22 0 01-11.24-11.21v-8.45c0-.06.06-.06.09-.06h78a12.42 12.42 0 0112.42 12.42zM460 387.17a81.31 81.31 0 01-17.5 2.93c-1.68.09-3.39.15-5.1.15h-89c-36 0-65.25-22.08-65.61-49.43H423.2a67.63 67.63 0 0026.39 40.12 66.69 66.69 0 0010.41 6.23z"
      />
      <path
        className="Scarica_ricevuta_svg__cls-1"
        d="M499.08 178.77L487.84 190a2.31 2.31 0 01-3.23 0l-14.2-14.2a2.3 2.3 0 00-3.24 0L452.79 190a2.32 2.32 0 01-3.24 0l-14.19-14.2a2.3 2.3 0 00-3.24 0L417.74 190a2.32 2.32 0 01-3.24 0l-14.2-14.2a2.29 2.29 0 00-3.23 0L382.65 190a2.31 2.31 0 01-3.23 0l-14.2-14.2a2.3 2.3 0 00-3.24 0L347.6 190a2.32 2.32 0 01-3.24 0l-11.48-11.48a2.29 2.29 0 00-3.91 1.61V323h111.15v2.55a48 48 0 002.9 14.26 13.68 13.68 0 019.89-4.15h17.29v-15a20.62 20.62 0 0120.58-20.64H503V180.39a2.29 2.29 0 00-3.92-1.62zm-23.54 119.79h-111a8.1 8.1 0 01-8.1-8.1v-5.83h111.04a8.06 8.06 0 018.06 8.09zm0-28.28h-111a8.09 8.09 0 01-8.1-8.09v-5.83h111.04a7.71 7.71 0 014.09 1.13 8 8 0 014 7zm0-28.27h-111a8.1 8.1 0 01-8.1-8.09v-5.84h111.04a8.06 8.06 0 018.06 8.09z"
      />
    </svg>
  );
}

export default SvgScaricaRicevuta;
