import * as React from "react";

function SvgAmici(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>
          {
            ".Amici_svg__cls-1{fill:#ffb400}.Amici_svg__cls-2{fill:#efac18;opacity:.59}"
          }
        </style>
      </defs>
      <path
        className="Amici_svg__cls-1"
        d="M441.27 233.49c0 2.45-.09 4.85-.3 7.22a78.92 78.92 0 01-12 36.69c-6.45 9.84-15 17.1-24.71 20.45a.31.31 0 01-.26.09q-25.12 7.21-51 12.37A44.18 44.18 0 01343 266a42.78 42.78 0 012.11-5.24c3.74-7.65 13.1-21.22 19.59-27.54 1.42-1.38 2.66-3 4.17-4.38a157.38 157.38 0 0114.56-12.68c11.56-8.59 29.05-17.49 39.53-20.24a147.72 147.72 0 01-3.22 23.34 149.61 149.61 0 0018.09-10.06 85 85 0 013.44 24.29zM473.84 314a97.84 97.84 0 01-45.38 82.71 32.68 32.68 0 01-3.35 2.06 40.18 40.18 0 01-19.33 4.9H292.94c2.2-9.07 4.6-18.05 7.22-27 1.46-5.11 3-10.22 4.6-15.29 0-.09 0-.22.09-.31.3-.94 1-3.05 1-3.05a43.58 43.58 0 0129-27.07 24.45 24.45 0 012.49-.6c.86-.13 1.68-.3 2.54-.47q17.26-3.09 34.2-7.13 21.07-4.9 41.55-11.22c18.95-5.71 37.51-12.33 55.68-19.68a96.76 96.76 0 012.53 22.15z"
      />
      <path
        className="Amici_svg__cls-2"
        d="M516.38 229.19c0 2.45-.09 4.86-.31 7.22a78.77 78.77 0 01-12 36.69 56.58 56.58 0 01-13.66 14.66c-.26-1.21-.52-2.37-.77-3.53l-3.87-16.33-15.51 6.28q-13.8 5.54-27.5 10.4c.9-1.16 1.72-2.37 2.53-3.61a92.84 92.84 0 0014.31-43.27c.26-2.67.35-5.46.35-8.51a104.64 104.64 0 00-1.42-17.27c11.56-8.59 29-17.48 39.53-20.23a148.6 148.6 0 01-3.21 23.31 148.25 148.25 0 0018.09-10.06 85.41 85.41 0 013.44 24.25zM548.94 314a97.78 97.78 0 01-45.37 82.71 32.68 32.68 0 01-3.35 2.06 40.21 40.21 0 01-19.34 4.9h-32.95A112 112 0 00492.49 314c0-1 0-2.07-.05-3.06 18.35-5.54 36.35-12 54-19.12a96.76 96.76 0 012.5 22.18z"
      />
    </svg>
  );
}

export default SvgAmici;
