import * as React from "react";

function SvgMatchHistory(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>
          {
            ".Match_history_svg__cls-1{fill:#ffb400}.Match_history_svg__cls-2{fill:#efac18;opacity:.84}"
          }
        </style>
      </defs>
      <path
        className="Match_history_svg__cls-1"
        d="M460.26 268.89c0 1.2-.05 2.39-.15 3.55a38.77 38.77 0 01-5.91 18 24.36 24.36 0 01-12.15 10 .13.13 0 01-.12.05q-12.36 3.54-25.09 6.08a21.62 21.62 0 01-6-14.95 21.37 21.37 0 011.1-6.82 20.14 20.14 0 011-2.58 62.24 62.24 0 019.63-13.54c.69-.67 1.3-1.45 2-2.15a78.16 78.16 0 017.16-6.23c5.68-4.22 14.28-8.59 19.43-9.95a72.91 72.91 0 01-1.58 11.47 72 72 0 008.89-4.94 42.2 42.2 0 011.79 12.01zM476.26 308.44A48.06 48.06 0 01454 349.09a17.26 17.26 0 01-1.64 1 19.8 19.8 0 01-9.51 2.41h-55.49c1.08-4.46 2.26-8.87 3.55-13.29.71-2.51 1.45-5 2.26-7.52v-.14l.48-1.5A21.45 21.45 0 01408 316.76a12 12 0 011.22-.29l1.25-.23c5.66-1 11.25-2.18 16.81-3.51q10.35-2.4 20.42-5.51c9.31-2.81 18.43-6.06 27.37-9.67a47.78 47.78 0 011.19 10.89z"
      />
      <path
        className="Match_history_svg__cls-2"
        d="M358.16 259.38c0 1 0 2 .13 3a33.11 33.11 0 005 15.4 20.85 20.85 0 0010.36 8.58.14.14 0 00.11 0q10.56 3 21.42 5.19A18.5 18.5 0 00399.4 273a17.7 17.7 0 00-.88-2.2 53.09 53.09 0 00-8.23-11.56c-.59-.58-1.11-1.24-1.75-1.84a66.45 66.45 0 00-6.11-5.32c-4.85-3.6-12.19-7.34-16.59-8.49a61.33 61.33 0 001.36 9.79 62.42 62.42 0 01-7.6-4.22 36 36 0 00-1.44 10.22zM387.06 327.65l-.44 1.31-.19.42-.11.53c-.08.3-.19.57-.27.87h-13a17.09 17.09 0 01-8.13-2 14.1 14.1 0 01-1.39-.87 41.1 41.1 0 01-19.05-34.71 39.5 39.5 0 011.08-9.29c7.62 3.08 15.41 5.85 23.35 8.25 5.75 1.76 11.55 3.34 17.45 4.71q7.1 1.68 14.36 3c.35.08.69.15 1 .21a6.06 6.06 0 011 .25 19.05 19.05 0 012.59.95 28.66 28.66 0 004.31 8l-.55.1-.31.06-.72.15h-.36c-.63.13-1.23.28-1.84.47a28.46 28.46 0 00-18.78 17.59z"
      />
      <path
        className="Match_history_svg__cls-1"
        d="M385.38 439.87c0-.08 0-.09 0 0zM386.91 439.87c0-.08 0-.08 0 0zM538.52 289.31a127.89 127.89 0 01-218.07 98.61 28.15 28.15 0 0135-4 102.47 102.47 0 0063.62 15.89c50.36-3.91 91-45.07 94.24-95.47a102.65 102.65 0 00-204.69-15.7l8-10.59.48-.65a5.28 5.28 0 014-2h22.54l-34.65 45.19a9.91 9.91 0 01-7.55 3.87h-10.56a.85.85 0 01-.35-.05 9.65 9.65 0 01-7.46-3.82l-34.65-45.19H271a5.24 5.24 0 014.13 2.17.94.94 0 01.26.3l8 10.64a127.83 127.83 0 01132-118.84c65.1 2.27 119.05 54.6 123.13 119.64z"
      />
    </svg>
  );
}

export default SvgMatchHistory;
