import * as React from "react";

function SvgLeaderboard(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Leaderboard_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Leaderboard_svg__cls-1"
        d="M295.23 292.24h38.53v99.92h-51v-83.74c.02-8.93 5.59-16.18 12.47-16.18zM538.78 310.56v62.24c0 10.69-5.58 19.36-12.45 19.36h-38.56v-81.6zM427.86 220.65l11.94-9.26c0 2.75-2.15 12.18-4.41 18.91a7.14 7.14 0 01-6.21 4.82 242.36 242.36 0 01-35.34 0 7.19 7.19 0 01-6.2-4.71 100.58 100.58 0 01-4.28-19l11.94 9.26 16.29-17.53zM362.92 250.74c-6.89 0-12.46 7.18-12.46 16v125.39h120.59V250.74zM425.77 351h-19.26v-44.39q0-5.16-5.26-5.16h-2.4a8.72 8.72 0 01-8.72-8.72v-9h15.29q10.57 0 15.46 5.21t4.89 15.73z"
      />
    </svg>
  );
}

export default SvgLeaderboard;
