import './PartyHStackSteps.css'
import * as Icons from "./icons2";
import React, {useMemo} from "react";
import {useCoreApiGetStacks} from "../utility/apiHelpers";

export default function PartyHStackSteps({party}) {
    const stacks = useCoreApiGetStacks();
    const stack = useMemo(() => party?.arena?.id && stacks?.find?.(s => s?.arena?.id === party?.arena?.id), [stacks, party?.arena?.id])
    return <div className="PartyHStackSteps">
        <div className={"step on"}>
            <Icons.Stack1/>
        </div>
        <div className="gap">
            <div style={{width: (stack?.winsCount >= 1 ? 100 : 50) + '%'}}>&nbsp;</div>
        </div>
        <div className={"step " + (stack?.winsCount >= 1 ? 'on' : '')}>
            <Icons.Stack2/>
        </div>
        <div className="gap">
            <div style={{width: (stack?.winsCount >= 1 ? (stack?.winsCount >= 2 ? 100 : 50) : 0) + '%'}}>&nbsp;</div>
        </div>
        <div className={"step " + (stack?.winsCount >= 2 ? 'on' : '')}>
            <Icons.FullStack/>
        </div>
        <div className="gap">
            <div style={{width: (stack?.winsCount >= 2 ? (stack?.inReward ? 100 : 50) : 0) + '%'}}>&nbsp;</div>
        </div>
        <div className={"step " + (stack?.inReward ? 'on' : '')}>
            <Icons.Gift/>
        </div>
    </div>
}