import * as React from "react";

function SvgInbox(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Inbox_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Inbox_svg__cls-1"
        d="M548.94 255.52H505.7A26.55 26.55 0 00482.07 270a14.79 14.79 0 00-.69 1.38 27 27 0 00-2.25 10.72v12.74a11.7 11.7 0 01-11.7 11.71h-93a11.71 11.71 0 01-11.71-11.71v-12.75a27 27 0 00-2.24-10.72 14.85 14.85 0 00-.7-1.38 26.44 26.44 0 00-23.63-14.47h-43.2l28.61-28.61a19.62 19.62 0 0113.85-5.74h171.08a19.62 19.62 0 0113.85 5.74z"
      />
      <path
        className="Inbox_svg__cls-1"
        d="M548.94 325.29v26.91A21.9 21.9 0 01527 374.1H314.85a21.91 21.91 0 01-21.91-21.9v-77.89h39.23A11.8 11.8 0 01344 286.1v12.19a26.67 26.67 0 002.12 10.49c.3.69.65 1.39 1 2.08A27 27 0 00371 325.29h99.92a27 27 0 0023.94-14.43c.34-.65.69-1.34 1-2a27.65 27.65 0 002.12-10.54V286.1a11.78 11.78 0 0111.79-11.79h39.13v51z"
      />
    </svg>
  );
}

export default SvgInbox;
