import React, {useEffect} from 'react';
import Echo from "laravel-echo";
import {getApiToken, getSignedInUser, isApiTokenValid} from "../utility/apiToken";
import {dispatchMatchmakingStarted, dispatchStopMatchmaking, setMatchmakingStatus} from "../contexts/MMContext";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";
import {addPartyInvitation, leaveParty, updateParty} from "../contexts/PartyContext";
import PartyInvitationToast from "./toasts/PartyInvitationToast";
import NoShowWarningToast from "./toasts/NoShowWarningToast";
import {updateNotifications} from "../contexts/NotificationsContext";
import {getServerErrorMessage} from "../utility/form";
import OrganizationEntryRequestToast from "./toasts/OrganizationEntryRequestToast";

const WsManager = ({mmDispatch, partyDispatch, notificationsDispatch}) => {
    const intl = useIntl();
    const isLogged = isApiTokenValid();

    // init websocket
    useEffect(() => {

        // init websocket
        window.Pusher = require('pusher-js');

        if (isLogged) {
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.WS_PUSHER_APP_KEY,
                wsHost: process.env.WS_PUSHER_HOST,
                wsPort: process.env.WS_PUSHER_PORT,
                wssPort: process.env.WS_PUSHER_PORT,
                forceTLS: false,
                encrypted: true,
                disableStats: true,
                enabledTransports: ['ws', 'wss'],
                authEndpoint: process.env.BACKEND_URL.replace(/\/$/, "")+"/api/v1/broadcasting/auth",
                withCredentials: true,
                auth: {headers: {'Authorization': 'Bearer ' + getApiToken()}},
            });
        }
        else {
            // Public unauthenticated visitors get no functionality through websockets...
            // NOTE: the following code only attaches listeners to privateChannelName,
            //       which is not valid with undefined user id.

            return () => {};

        }
        // DEBUG
        /*window.Echo.connector.socket.on('connect', function () {
            console.log("connesso");
        });*/

        const privateChannelName = "users." + getSignedInUser().id;

        //matchmaking event listeners
        const handleStartMatchmaking = (e) => {
            console.debug('handleStartMatchmaking', e);
            dispatchMatchmakingStarted(mmDispatch, e);
        }
        const handleUpdateMatchmaking = (e) => {
            console.debug('handleUpdateMatchmaking', e);
            setMatchmakingStatus(mmDispatch, e);
        }
        const handleStopMatchmaking = (e) => {
            console.debug('handleStopMatchmaking', e);
            dispatchStopMatchmaking(mmDispatch);
        }
        const handleAreYouReady = (notification) => {
            console.debug('handleAreYouReady', notification);
            if (notification.type === 'App\\Notifications\\AreYouReadyNotification') {
                //type and id are the type (notification class FQN) and the id (db uuid) of the notification
                delete notification.type;
                delete notification.id;
                setMatchmakingStatus(mmDispatch, notification);
            }
        }
        const handleMatchStarted = (e) => {
            console.debug('handleMatchStarted', e);
            dispatchStopMatchmaking(mmDispatch);
            toast.success(intl.formatMessage({defaultMessage: "Match avviato"}));
            window.location.href = '/matches/'+e.match.id;
        }
        window.Echo.private(privateChannelName)
            .listen('MatchmakingStarted', handleStartMatchmaking)
            .listen('MatchmakingUpdated', handleUpdateMatchmaking)
            .listen('MatchmakingStopped', handleStopMatchmaking)
            .listen('MatchmakingTimedOut', handleStopMatchmaking)
            .notification(handleAreYouReady)
            .listen('MatchStarted', handleMatchStarted);

        //party event listeners
        const handleInvitationSent = event => {
            console.debug('handleInvitationSent', event);
            addPartyInvitation(partyDispatch, event);
        }
        const handlePartyUpdated = event => {
            console.debug('handlePartyUpdated', event);
            updateParty(partyDispatch, {party: event.party});
        }
        const handlePartyDeleted = event => {
            console.debug('handlePartyDeleted', event);
            leaveParty(partyDispatch);
        }
        const handlePartyInvitation = notification => {
            console.debug('handlePartyInvitation', notification);
            if (notification.type === 'App\\Notifications\\PartyInvitationNotification') {
                updateNotifications(notificationsDispatch)
                    .catch(error => toast.error(getServerErrorMessage(error)));
                toast(<PartyInvitationToast notification={notification} />, {
                    autoClose: false,
                    closeOnClick: false,
                })
            }
        }
        window.Echo.private(privateChannelName)
            .listen('PartyUpdated', handlePartyUpdated)
            .listen('PartyDeleted', handlePartyDeleted)
            .listen('PartyInvitationSent', handleInvitationSent)
            .listen('PartyInvitationAccepted', handlePartyUpdated)
            .listen('PartyInvitationRefused', handlePartyUpdated)
            .notification(handlePartyInvitation);

        //match event listeners (some other listeners are only in Match.js page)
        const handleMatchNoShow = notification => {
            console.debug('handleMatchNoShow', notification);
            if (notification.type === 'App\\Notifications\\NoShowWarningNotification') {
                updateNotifications(notificationsDispatch)
                    .catch(error => toast.error(getServerErrorMessage(error)));
                toast(<NoShowWarningToast notification={notification} />, {
                    autoClose: false,
                    closeOnClick: false,
                })
            }
        }
        window.Echo.private(privateChannelName)
            .notification(handleMatchNoShow);

        //organization event listeners
        const handleOrganizationEntryRequest = notification => {
            console.debug('handleOrganizationEntryRequest', notification);
            if (notification.type === 'App\\Notifications\\OrganizationEntryRequestNotification') {
                updateNotifications(notificationsDispatch)
                    .catch(error => toast.error(getServerErrorMessage(error)));
                toast(<OrganizationEntryRequestToast notification={notification} />, {
                    autoClose: false,
                    closeOnClick: false,
                })
            }
        }
        window.Echo.private(privateChannelName)
            .notification(handleOrganizationEntryRequest);

        //other event listeners
        const handleProfileCompleted = notification => {
            console.debug('handleProfileCompleted', notification);
            if (notification.type === 'App\\Notifications\\ProfileCompletedNotification') {
                updateNotifications(notificationsDispatch)
                    .catch(error => toast.error(getServerErrorMessage(error)));
            }
        }
        const handleSeasonAward = notification => {
            console.debug('handleSeasonAward', notification);
            if (notification.type === 'App\\Notifications\\SeasonAwardNotification') {
                updateNotifications(notificationsDispatch)
                    .catch(error => toast.error(getServerErrorMessage(error)));
            }
        }
        const handleSupportTicketCreated = notification => {
            console.debug('handleSupportTicketCreated', notification);
            if (notification.type === 'App\\Notifications\\SupportTicketCreatedNotification') {
                updateNotifications(notificationsDispatch)
                    .catch(error => toast.error(getServerErrorMessage(error)));
            }
        }
        window.Echo.private(privateChannelName)
            .notification(handleProfileCompleted)
            .notification(handleSeasonAward)
            .notification(handleSupportTicketCreated);

        return () => {
            // destroy listener
            window.Echo.private(privateChannelName)
                //destroy matchmaking listeners
                .stopListening('MatchmakingStarted', handleStartMatchmaking)
                .stopListening('MatchmakingUpdated', handleUpdateMatchmaking)
                .stopListening('MatchmakingStopped', handleStopMatchmaking)
                .stopListening('MatchmakingTimedOut', handleStopMatchmaking)
                .stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', handleAreYouReady)
                .stopListening('MatchStarted', handleMatchStarted)
                //destroy party listeners
                .stopListening('PartyUpdated', handlePartyUpdated)
                .stopListening('PartyDeleted', handlePartyDeleted)
                .stopListening('PartyInvitationSent', handleInvitationSent)
                .stopListening('PartyInvitationAccepted', handlePartyUpdated)
                .stopListening('PartyInvitationRefused', handlePartyUpdated)
                .stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', handlePartyInvitation)
                //destroy match listeners
                .stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', handleMatchNoShow)
                //destroy organization listeners
                .stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', handleOrganizationEntryRequest)
                //destroy other listeners
                .stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', handleProfileCompleted)
                .stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', handleSeasonAward)
                .stopListening('.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', handleSupportTicketCreated);
        }
    }, [isLogged, mmDispatch, intl, partyDispatch, notificationsDispatch]);

    return null; //it doesn't return anything because it doesn't need to
};

export default WsManager;