import * as React from "react";

function SvgLoading(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Loading_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Loading_svg__cls-1"
        d="M487.29 188.17h-.15a127.7 127.7 0 00-114.9 109.47 125.38 125.38 0 00-1.29 18 129 129 0 003.68 30.6 8.17 8.17 0 01.15 1.59 8 8 0 01-8 8h-35.69c-1 0-2.1-.05-3.14-.15s-2.1-.2-3.09-.39a26.43 26.43 0 01-2.89-.65c-1.25-.35-2.44-.75-3.64-1.2a12.51 12.51 0 01-1.74-.75c-.35-.15-.7-.29-1-.44-.5-.25-1-.5-1.49-.8a17.27 17.27 0 01-2.05-1.25c-.64-.4-1.29-.84-1.89-1.29a32.39 32.39 0 01-3.69-3.14c-.7-.65-1.39-1.4-2-2.14s-1.25-1.55-1.84-2.3a35.64 35.64 0 01-5-9.91c-.05-.15-.1-.35-.15-.55s-.1-.35-.15-.55a24.29 24.29 0 01-.69-2.64 127.92 127.92 0 01113.3-157.54c3.64-.35 7.32-.5 11.06-.5a127.46 127.46 0 0166.29 18.53zM548.92 297.64a128 128 0 01-116.34 127.45c-3.84.35-7.72.55-11.66.55a127.6 127.6 0 01-66.37-18.54v-.05h.05a126.68 126.68 0 0044-11.95A128.15 128.15 0 00467.76 249a8.28 8.28 0 01-.15-1.6 8 8 0 018-8h35.27a35.2 35.2 0 0133.49 24.27 5 5 0 01.2.64 15.45 15.45 0 01.49 1.95 1.33 1.33 0 01.15.6c.1.34.15.69.25 1.09a.18.18 0 01.05.15 127.92 127.92 0 013.41 29.54z"
      />
    </svg>
  );
}

export default SvgLoading;
