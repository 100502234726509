import * as React from "react";

function SvgAchievement(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Achievement_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Achievement_svg__cls-1"
        transform="rotate(-45 420.359 272.972)"
        d="M403.36 255.98h33.99v33.99h-33.99z"
      />
      <path
        className="Achievement_svg__cls-1"
        d="M440.7 188.27a82.62 82.62 0 00-14.21-2.27c-2.11-.17-4.26-.27-6.44-.27s-4.29.07-6.41.27a83.14 83.14 0 00-14.2 2.31l1.16-1 19.75-17.61 19.23 17.61zM491.77 226.89a83.24 83.24 0 00-29-29.34l1.59.1 26.4 1.55 1 26.13zM377.7 197.32a82.86 82.86 0 00-28.74 28.58 3.39 3.39 0 00-.27.43l.07-1.55 1-25.87 26.36-1.52zM440.7 358.08l8.85 16.65 27.15 50.91 7.69-25.18 25.21 7.7-27.14-50.95-8.29-15.59a81.6 81.6 0 01-33.47 16.46zM420.06 201.56A71.41 71.41 0 10491.47 273a71.41 71.41 0 00-71.41-71.44zm25.07 95a34.19 34.19 0 119.41-23.56 34.11 34.11 0 01-9.41 23.53zM401.19 358.08l-8.85 16.65-27.15 50.91-7.69-25.18-25.21 7.7 27.14-50.95 8.29-15.59a81.6 81.6 0 0033.47 16.46z"
      />
    </svg>
  );
}

export default SvgAchievement;
