import React, {createContext, useContext, useState} from "react";

const ThemeContext = createContext(null);

export function useThemeContext() {
    return useContext(ThemeContext)
}

export const ThemeStore = ({children}) => {
    const [theme, setTheme] = useState('');
    return (
        <ThemeContext.Provider value={{theme, setTheme}}>
            <div className={theme}>
                {children}
            </div>
        </ThemeContext.Provider>
    )
};
