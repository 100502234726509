import * as React from "react";

function SvgModificaProfilo(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Modifica_profilo_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Modifica_profilo_svg__cls-1"
        d="M465.21 216c0 3-.1 6-.37 8.88-1.37 17.22-6.76 32.81-14.8 45.13-7.92 12.1-18.44 21-30.38 25.15a.38.38 0 01-.32.11q-30.91 8.88-62.78 15.22a54.45 54.45 0 01-9.62-60.94c4.6-9.4 16.12-26.1 24.1-33.87 1.74-1.69 3.28-3.65 5.13-5.39a191.72 191.72 0 0117.91-15.59c14.22-10.57 35.72-21.51 48.62-24.89a182.26 182.26 0 01-4 28.7 183.15 183.15 0 0022.3-12.4 104.75 104.75 0 014.21 29.89zM531.91 346.45l-6.29 6.29-14.69 14.69-1.27 1.27a13.09 13.09 0 00-.43-1.53 22.52 22.52 0 00-10.3-13.48l-1.11-.63a23 23 0 00-26.74 4.06l-6 6-3.88 3.88-15.86 15.9a.39.39 0 01-.63-.42 12.31 12.31 0 00.58-1.48 12.07 12.07 0 00-.63-9.25 12.41 12.41 0 00-8.93-6.55 51.38 51.38 0 015.27-15.84l.16-.32a12 12 0 0017.06-7 12.21 12.21 0 00-1.58-10.83 51.26 51.26 0 0115.22-7.61 8.76 8.76 0 00.69 1.64 12.18 12.18 0 0022.88-3.33 47.45 47.45 0 019.51 2.48 51.61 51.61 0 016.4 2.75c.05.05.15.1.21.16a10 10 0 00-.69 1.58 12.17 12.17 0 0018.55 13.79c.86 1.19 1.7 2.46 2.5 3.78z"
      />
      <path
        className="Modifica_profilo_svg__cls-1"
        d="M495 363.68a12.28 12.28 0 014.6 11.52 15.33 15.33 0 01-.48 2.06 13.91 13.91 0 01-.79 1.9 11.29 11.29 0 01-2.27 3.07 12.43 12.43 0 01-13.27 2.85 10.53 10.53 0 01-1.32-.58 12.36 12.36 0 01-6.66-12.63v-.05a13.07 13.07 0 01.42-1.91 12.62 12.62 0 01.9-2.27A12.47 12.47 0 01493 362.3a7.56 7.56 0 012 1.38z"
      />
      <path
        className="Modifica_profilo_svg__cls-1"
        d="M538.78 381.86a52 52 0 01-5.24 15.9l-.15.32a9.2 9.2 0 00-1.7-.74 12.18 12.18 0 00-13.79 18.55 50.9 50.9 0 01-15.22 7.61 9.94 9.94 0 00-.69-1.59 12.18 12.18 0 00-22.88 3.33 51.16 51.16 0 01-15.9-5.28c-.11 0-.16 0-.22-.11a9.52 9.52 0 00.64-1.58 11.92 11.92 0 00-1.63-10.78 11.66 11.66 0 00-6.13-4.65 12.24 12.24 0 00-10.78 1.58c-.9-1.21-1.74-2.48-2.53-3.75l22.24-22.25a6.49 6.49 0 00.43 1.48 22.25 22.25 0 0010.83 13.79 4.44 4.44 0 00.58.32 22.92 22.92 0 0026.74-4.07l25.74-25.73c.31-.32.79 0 .58.37a9.18 9.18 0 00-.64 1.58 12.37 12.37 0 00.74 9.14 12.15 12.15 0 008.98 6.56z"
      />
      <path
        className="Modifica_profilo_svg__cls-1"
        d="M505.15 311.84a59 59 0 00-7.92-1.8 11.66 11.66 0 00-1.85-.16A12.4 12.4 0 00483.22 320a12 12 0 00-11.41-8.4 11.76 11.76 0 00-3.54.58 62.94 62.94 0 00-18.76 9.35 12.42 12.42 0 00-2.86 16.91 11.93 11.93 0 00-5.55-1.37 13.08 13.08 0 00-4 .68 11.87 11.87 0 00-6.66 5.82 2.93 2.93 0 01-.16.26 65.21 65.21 0 00-6.44 19.5 12.35 12.35 0 0010 14.06c5.24.9 4.87 7.77 1.54 13a14.65 14.65 0 01-1.27 1.8c-.27.26-.48.52-.74.79a12 12 0 00-1.11 13.85c1 1.63 2.06 3.17 3.12 4.7a12.46 12.46 0 0010 5 12.16 12.16 0 006.92-2.17 2.47 2.47 0 00-.26.58c-.85.63-1.75 1.22-2.65 1.8a40.22 40.22 0 01-4.12 2.53 49.48 49.48 0 01-23.78 6H282.78c2.69-11.15 5.65-22.2 8.87-33.24 1.8-6.29 3.65-12.58 5.66-18.82.05-.1.05-.26.1-.37.37-1.16 1.22-3.75 1.22-3.75a53.59 53.59 0 0135.67-33.29 27.49 27.49 0 013.07-.74c1.05-.16 2.06-.37 3.11-.58q21.25-3.81 42.07-8.77 25.92-6 51.1-13.8c23.31-7 46.14-15.16 68.49-24.2a113.61 113.61 0 013.01 24.13z"
      />
    </svg>
  );
}

export default SvgModificaProfilo;
