import React, {useReducer} from 'react';
import axios from "axios";
import {getApiUrl} from "../utility/apiHelpers";
import NoShowWarningNotification from "../components/notifications/NoShowWarningNotification";
import PartyInvitationNotification from "../components/notifications/PartyInvitationNotification";
import OrganizationEntryRequestNotification from "../components/notifications/OrganizationEntryRequestNotification";
import ExpiredStackNotification from "../components/notifications/ExpiredStackNotification";
import ProfileCompletedNotification from "../components/notifications/ProfileCompletedNotification";
import SeasonAwardNotification from "../components/notifications/SeasonAwardNotification";
import SupportTicketCreatedNotification from "../components/notifications/SupportTicketCreatedNotification";

const initialState = null;

export const NotificationsStateContext = React.createContext(initialState);
export const NotificationsDispatchContext = React.createContext(null);

/**
 * Register here the component associated to the notification type
 */
export const managedNotificationTypes = {
    'App\\Notifications\\NoShowWarningNotification': NoShowWarningNotification,
    'App\\Notifications\\PartyInvitationNotification': PartyInvitationNotification,
    'App\\Notifications\\OrganizationEntryRequestNotification': OrganizationEntryRequestNotification,
    'App\\Notifications\\ExpiredStackNotification': ExpiredStackNotification,
    'App\\Notifications\\ProfileCompletedNotification': ProfileCompletedNotification,
    'App\\Notifications\\SeasonAwardNotification': SeasonAwardNotification,
    'App\\Notifications\\SupportTicketCreatedNotification': SupportTicketCreatedNotification,
};

export const NotificationsReducer = (state, action) => {
    let notifications;
    switch (action.type) {
        case 'SET':
            notifications = action?.payload?.data?.filter(n => managedNotificationTypes[n.type]);
            return {...action.payload, data: notifications ?? []};
        case 'LOAD_MORE':
            //in this case the payload must be the server response json
            action.payload.data.push(...state.data);
            return {...action.payload};
        case 'DELETE':
            notifications = Array.from(state.data);
            //in this case the payload must be the notification id
            let deletableIndex = notifications.findIndex(n => n.id === action.payload);
            if (deletableIndex !== -1) {
                notifications.splice(deletableIndex, 1);
                return {...state, data: notifications};
            } else {
                return state;
            }
        default:
            return state;
    }
};

export const NotificationsStore = ({children}) => {
    const [state, dispatch] = useReducer(NotificationsReducer, initialState);
    return (
        <NotificationsDispatchContext.Provider value={dispatch}>
            <NotificationsStateContext.Provider value={state}>
                {children}
            </NotificationsStateContext.Provider>
        </NotificationsDispatchContext.Provider>
    )
};

export function setNotifications(notificationsDispatch, notificationsResponse) {
    notificationsDispatch({type: 'SET', payload: notificationsResponse});
}

export function deleteNotification(notificationsDispatch, notificationId) {
    notificationsDispatch({type: 'DELETE', payload: notificationId});
}

export function updateNotifications(notificationsDispatch) {
    return axios.get(getApiUrl('users/notifications'), {params: {onlyUnread: 1}})
        .then(response => {
            setNotifications(notificationsDispatch, response.data);
        });
}

export function loadMoreNotifications(notificationsDispatch, nextUrl) {
    return axios.get(nextUrl)
        .then(response => {
            notificationsDispatch({type: 'LOAD_MORE', payload: response.data});
        });
}
