import React from 'react';
// import {checkIOSApp} from "../utility/userAgent";
// import {getUA} from "react-device-detect";
import PullToRefresh from "react-simple-pull-to-refresh";
import {FormattedMessage} from "react-intl";

const IosPullToReloadWrapper = (props) => {
    // const isIOSApp = checkIOSApp(getUA);
    const isIOSApp = false;

    const handleRefresh = () => {
        return new Promise(resolve => {
            setTimeout(resolve, 500);
        }).then(() => {
            window && window.location.reload();
        });
    }

    const pulling = <div className="mx-auto text-center"><FormattedMessage description="Pull to reload the page" defaultMessage="Tira verso il basso per ricaricare la pagina" /></div>;
    const refreshing = <div className="mx-auto text-center"><FormattedMessage description="Reloading..." defaultMessage="Caricamento..." /></div>;

    return (
        <PullToRefresh isPullable={isIOSApp} onRefresh={handleRefresh}
                       pullDownThreshold={150} maxPullDownDistance={300} resistance={2}
                       pullingContent={pulling} refreshingContent={refreshing}>
            {props.children}
        </PullToRefresh>
    );
};

export default IosPullToReloadWrapper;