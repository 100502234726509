import * as React from "react";

function SvgDashboard(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Dashboard_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Dashboard_svg__cls-1"
        d="M300.33 169.7h95.25v59.85a14.7 14.7 0 01-14.7 14.7h-95.24v-59.86a14.7 14.7 0 0114.69-14.69z"
        transform="rotate(180 340.61 206.97)"
      />
      <path
        className="Dashboard_svg__cls-1"
        d="M300.9 272.46h94.81v137.8a15.44 15.44 0 01-15.44 15.44h-94.81V287.9a15.44 15.44 0 0115.44-15.44zM425.66 351.06h95.25a14.7 14.7 0 0114.7 14.7v59.85h-95.25a14.7 14.7 0 01-14.7-14.7v-59.85zM425.49 169.72h94.81a15.44 15.44 0 0115.44 15.44V323h-94.81a15.44 15.44 0 01-15.44-15.44V169.72z"
      />
    </svg>
  );
}

export default SvgDashboard;
