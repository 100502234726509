import './PartyTripleTopBar.css'
import * as Icons from "./icons2";
import React from "react";
/* import InlineSVG from "./InlineSVG"; */

export default function PartyTripleTopBar({party, onCloseRequested}) {
    return <div className="PartyTripleTopBar">
        <div className="icon">
            {/* party?.arena?.game?.icon?.url && <InlineSVG url={party?.arena?.game?.icon?.url}/> */}
            {party?.arena?.game?.icon?.url && <img src={party?.arena?.game?.icon?.url} alt=""/>}
        </div>
        <div className="title">
            <span className="game">{party?.arena?.game?.name}</span>
            <span className="code">#IT59067 {/* TODO FIXME */}</span>
        </div>
        {onCloseRequested &&
        <div className="close" onClick={onCloseRequested}>
            <Icons.Rifiuta/>
        </div>
        }
    </div>
}