import * as React from 'react'
import SecondaryButton from "./components/buttons/SecondaryButton";


export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.error(error)
    }

    render() {
        if (this.state.hasError) {
            return <div style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                flexFlow: 'column',
                flexGrow: 1,
                minHeight: '50vh',
            }}>
                <span>Si è verificato un errore.</span>
                <br/>
                <SecondaryButton onClick={() => {
                    window.location.reload()
                }}>Ricarica</SecondaryButton>
            </div>;
        }
        return this.props.children;
    }
}