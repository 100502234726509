import * as React from "react";

function SvgDoubleUpArrow(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Double_up_arrow_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Double_up_arrow_svg__cls-1"
        d="M282.78 317.34l101.16-101.21a.31.31 0 00.16-.16 16.24 16.24 0 0111.42-4.68H426a16.31 16.31 0 0111.45 4.71.31.31 0 00.16.16l101.17 101.18h-56.26a17.14 17.14 0 01-11.82-4.73 4.81 4.81 0 01-.68-.67l-55.85-55.82-3.41-3.41-3.38 3.41-55.46 55.42-1.43 1.46a17.25 17.25 0 01-11.49 4.34z"
      />
      <path
        className="Double_up_arrow_svg__cls-1"
        d="M329 384l64.66-64.68a.19.19 0 00.1-.1 10.41 10.41 0 017.31-3h19.5a10.42 10.42 0 017.3 3 .19.19 0 00.1.1L492.59 384h-36a10.91 10.91 0 01-7.55-3 2.49 2.49 0 01-.43-.43L412.9 344.9l-2.18-2.18-2.15 2.18-35.45 35.42-.91.91a11 11 0 01-7.33 2.79z"
      />
    </svg>
  );
}

export default SvgDoubleUpArrow;
