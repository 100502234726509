import * as React from "react";

function SvgLogout(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Logout_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Logout_svg__cls-1"
        d="M457 391.23l31.16-30.14v47.39h-54.92V200.06l-24.54-13.17-32.23-17.25h69.46A44.41 44.41 0 01488.17 214c0 1-.05 2-.1 3l-31.06-30v67.22c0 .73.05 1.46.1 2.19a26.52 26.52 0 006.7 15.55l.34.39 17.4 16.82-16.62 16-1.46 1.51-.29.38A26.19 26.19 0 00457 324z"
      />
      <path
        className="Logout_svg__cls-1"
        d="M410.35 205v190.21a30.41 30.41 0 01-44.57 26.93l-.58-.29-52.64-28.24V195a25.33 25.33 0 0125.32-25.32h6.42l57.3 30.72zM529.33 282.21v13.7a6.9 6.9 0 01-2.33 5.15v.1l-35 32-3.83 3.5-11 10.11v-25.34a7.39 7.39 0 012.33-5.35 2 2 0 01.3-.29l8.41-7.73 20.75-19-1.65-1.51-15.27-14-3.83-3.5-8.21-7.53-.68-.63a7.53 7.53 0 01-2.14-5.2v-25.32l5.15 4.71 5.89 5.4L492 245l35 32v.09a6.94 6.94 0 012.33 5.12z"
      />
    </svg>
  );
}

export default SvgLogout;
