import moment from "moment";
import set from 'lodash/set'
import {notifyLoggedIn, notifyLoggedOut} from "./containerAppBridge";

/**
 * Read token from localStorage
 * @returns {object|boolean}
 */
export function getSignedInUser() {
    if (localStorage.getItem('loggedIn') !== null) {
        return JSON.parse(localStorage.getItem('loggedIn'));
    }

    return false;
}

export function updateSignedInUser(key, value) {
    let loggedIn;
    let item = localStorage.getItem('loggedIn');
    if ( item !== null) {
        loggedIn = JSON.parse(item);
    } else {
        loggedIn = {};
    }
    loggedIn = set(loggedIn, key, value);
    localStorage.setItem('loggedIn', JSON.stringify(loggedIn));
    notifyLoggedIn(loggedIn);
}

/**
 * get api token string if user is signed in
 * @returns {string|null}
 */
export function getApiToken() {
    let signedIn = getSignedInUser();

    return signedIn ? signedIn.api_token : null;
}

/**
 * Get api token expiry string if user is signed in
 * @returns {string|null}
 */
export function getApiTokenExpiry() {
    let signedIn = getSignedInUser();

    return signedIn ? signedIn.api_token_expiry : null;
}

/**
 * Check token validity
 * @returns {null|boolean}
 */
export function isApiTokenValid(signedIn = getSignedInUser()) {
    return signedIn && signedIn.api_token && moment.utc(signedIn.api_token_expiry).isAfter(moment.utc());
}

/**
 * Convert the passed object to a json string and store it in localStorage
 * @param loggedInUser
 */
export function setApiToken(loggedInUser = {}) {
    localStorage.setItem("loggedIn", JSON.stringify(loggedInUser))
    notifyLoggedIn(loggedInUser);
}

/**
 * delete api token from localStorage
 */
export function deleteApiToken() {
    notifyLoggedOut(getSignedInUser());
    localStorage.removeItem("loggedIn");
}
