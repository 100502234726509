import * as React from "react";

function SvgInviaMessaggio(props) {
  return (
    <svg
      {...props}
      id=""
      xmlns="http://www.w3.org/2000/svg"
      viewBox="210 149 421 298"
      width="1em"
      height="1em"
      className={
        "SvgIcon " +
        (props?.unshift ? "unshift " : "") +
        (props?.className || "")
      }
      preserveAspectRatio="xMidYMid meet"
    >
      <defs>
        <style>{".Invia_messaggio_svg__cls-1{fill:#ffb400}"}</style>
      </defs>
      <path
        className="Invia_messaggio_svg__cls-1"
        d="M538.71 179.25l-68.13 246.37-53.29-53.29c-3.35-3.35-4.12-8.73-2.65-14.66a11.2 11.2 0 01.35-1.36l16.78-62.58 106.28-116.3a1.63 1.63 0 01.66 1.82zM531 170.35L416.59 274.91l-1.92 1.71-61.78 16.58-2.89.8c-5.65 1.19-10.71.38-14-2.86l-53.29-53.3 246.37-68.09a1.69 1.69 0 011.92.6zM346.91 345.5l16 16c2.33 2.33 6.06 2.8 10.26 1.73a.35.35 0 01.23-.06l12.79-3.44a10.78 10.78 0 007.6-7.61l8.75-32.63 5-18.65-18.65 5-32.64 8.75a10.77 10.77 0 00-7.6 7.6L345.23 335a.33.33 0 01-.06.24c-1.1 4.17-.59 7.92 1.74 10.26z"
      />
    </svg>
  );
}

export default SvgInviaMessaggio;
